import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Html5Qrcode } from 'html5-qrcode';
import { TestIds } from 'utils/testing/testIds';

const { QR_CODE_SCANNER } = TestIds;

const HtmlQrcodePlugin = ({ setCameraPermission, onScan }) => {
  const [scanner, setScanner] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    let html5QrCode;

    Html5Qrcode?.getCameras()
      .then(() => {
        if (controller.signal?.aborted) return;

        html5QrCode = new Html5Qrcode('reader', {
          experimentalFeatures: {
            useBarCodeDetectorIfSupported: true
          }
        });
        const qrCodeSuccessCallback = (decodedText) => onScan(decodedText);
        const config = {
          fps: 10,
          qrbox: { width: 210, height: 210 }
        };

        html5QrCode
          .start({ facingMode: 'environment' }, config, qrCodeSuccessCallback)
          .then(() => setScanner(true));
      })
      .catch(() => {
        if (!controller?.signal?.aborted) setCameraPermission(false);
      });

    return () => {
      html5QrCode?.stop && html5QrCode.stop();
      controller.abort();
    };
  }, [onScan, setCameraPermission]);

  return (
    <div
      data-testid={QR_CODE_SCANNER}
      className={[`scanner-container`, scanner ? `scanner-container-border` : ``].join(' ')}>
      <div id="reader" className={[`scanner`, scanner ? `scanner-border` : ``].join(' ')} />
    </div>
  );
};

HtmlQrcodePlugin.propTypes = {
  setCameraPermission: PropTypes.func,
  onScan: PropTypes.func
};

export default HtmlQrcodePlugin;
