import React from 'react';
import 'styles/primaryButton.scss';

interface Props {
  children: JSX.Element[] | JSX.Element | string;
  className?: string;
  onClick?: () => void;
  loading?: boolean;
  buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

export default function PrimaryButton({
  children,
  className,
  onClick,
  loading,
  buttonType
}: Props) {
  return (
    <button
      className={['primary-button-cont', className ?? '', loading ? 'opacity-5' : ''].join(' ')}
      disabled={loading}
      onClick={onClick}
      type={buttonType || 'button'}>
      {children}
    </button>
  );
}
