import React from 'react';

import { OrderItem } from 'types/order';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

interface Item {
  item: OrderItem;
  index: number;
  onChange: (a: OrderItem) => void;
  onDelete: (id: string) => void;
}

export default function OrderItemForm({
  item: { id, description, quantity, serial_number },
  index,
  onChange,
  onDelete
}: Item) {
  return (
    <div className="item-card">
      <div className="head-row">
        <label htmlFor="itemDescription" className="label">
          Item Description
        </label>
        <FontAwesomeIcon
          icon={faCircleXmark}
          style={{ color: '#ff0000' }}
          onClick={() => onDelete(id)}
        />
      </div>
      <input
        id="itemDescription"
        value={description}
        onChange={(e) => onChange({ id, description: e.target.value, quantity, serial_number })}
        className="input"
        // required={index === 0}
      />
      <hr className="border-line" />

      <label htmlFor="itemQuantity" className="label">
        Quantity
      </label>
      <input
        id="itemQuantity"
        type="number"
        value={quantity}
        onChange={(e) => onChange({ id, description, quantity: e.target.value, serial_number })}
        className="input"
        // required={index === 0}
      />
      <hr className="border-line" />

      <label htmlFor="itemSerialNumber" className="label">
        Serial Number (Optional)
      </label>
      <input
        id="itemSerialNumber"
        value={serial_number}
        onChange={(e) => onChange({ id, description, quantity, serial_number: e.target.value })}
        className="input"
      />
    </div>
  );
}
