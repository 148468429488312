import React, { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { ROUTES } from 'constants/index';
import { getCustomerToken, showErrorMessage } from 'utils';

export default function CustomerProtectedRoutes({
  children
}: {
  children: JSX.Element;
}): JSX.Element {
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index') || '';
  const authenticated = getCustomerToken(index);

  useEffect(() => {
    !authenticated && showErrorMessage('Link is broken. Please try with the correct link');
  }, [authenticated]);

  return authenticated ? children : <Navigate to={ROUTES.customer.storeName} />;
}
