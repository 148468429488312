import React, { useEffect } from 'react';
import 'styles/layout.scss';

import AdminErrorPage from 'pages/admin/errorPage';

import Header from 'components/shared/header/index';
import useOnlineStatus from 'components/shared/hooks/useOnlineStatus';
import Logo from 'assets/images/logo2.svg';

import UserIcon from 'assets/images/user-icon-sm.svg';
import PageIcon from 'assets/images/page-icon-sm.svg';
import { capitalizeFirstLetter, truncateText } from 'utils';
import { TestIds } from 'utils/testing/testIds';

const { LAYOUT_ORDER_ID, LAYOUT_CUSTOMER_NAME } = TestIds;

interface Props {
  children: JSX.Element | JSX.Element[];
  lockerInfo?: boolean;
  menu?: boolean;
  backIcon?: boolean;
  customerName?: string;
  orderId?: string;
  menuOnClick?: () => void;
  onBackClick?: () => void;
  navigateToHome?: boolean;
  logoIcon?: string;
}

export default function Layout({
  children,
  lockerInfo,
  menu,
  menuOnClick,
  backIcon,
  onBackClick,
  customerName,
  orderId,
  navigateToHome,
  logoIcon = Logo
}: Props) {
  const isOnline = useOnlineStatus();
  const brandingLogo = logoIcon === '' ? Logo : logoIcon;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return isOnline ? (
    <div className="app-layout">
      <Header
        menu={menu}
        menuOnClick={menuOnClick}
        backIcon={backIcon}
        onBackClick={onBackClick}
        navigateToHome={navigateToHome}
        logoIcon={brandingLogo}
      />

      {lockerInfo && (
        <div className="user-info">
          <div className="name">
            <img src={UserIcon} />
            <div className="name-text" data-testid={LAYOUT_CUSTOMER_NAME}>
              {capitalizeFirstLetter(customerName ?? '')}
            </div>
          </div>
          <div className="code">
            <img src={PageIcon} />
            <div className="code-text" data-testid={LAYOUT_ORDER_ID}>
              {orderId ? orderId : ''}
            </div>
          </div>
        </div>
      )}

      {children}
    </div>
  ) : (
    <AdminErrorPage />
  );
}
