import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Pusher from 'pusher-js';

import { getPermissions, showNotification } from 'services/notifications';
import { getUserId } from 'utils';
import { DEFAULT_COLOR } from 'constants/index';

const pusherApiKey = process.env.REACT_APP_PUSHER_API_KEY!;

interface IAccount {
  id: number;
  name: string;
  active: boolean;
}

type Action =
  | { type: 'setData'; data: IAccount[] }
  | { type: 'setActive'; id: number }
  | { type: 'setPusher'; pusher: any }
  | { type: 'setColor'; color: string }
  | { type: 'setLogo'; logo: string };

type Dispatcher = (action: Action) => void;

type ContextData = {
  accounts: IAccount[];
  pusher: any;
  color: string;
  logo: string;
};

type MessageFormat = {
  title: string;
  text: string;
};

type ContextValue = ContextData & { dispatch: Dispatcher };

const AccountsContext = createContext<ContextValue | null>(null);

function accountReducer(state: ContextValue, action: Action): any {
  const { accounts } = state;

  switch (action.type) {
    case 'setData': {
      return {
        ...state,
        accounts: action.data
      };
    }

    case 'setActive': {
      const updatedAccounts = accounts.map((obj: { id: number }) => {
        if (obj.id == action.id) {
          return { ...obj, active: true };
        } else {
          return { ...obj, active: false };
        }
      });

      return {
        ...state,
        accounts: updatedAccounts
      };
    }

    case 'setPusher':
      return {
        ...state,
        pusher: action.pusher
      };

    case 'setColor':
      return {
        ...state,
        color: action.color
      };

    case 'setLogo':
      return {
        ...state,
        logo: action.logo
      };

    default: {
      return { accounts };
    }
  }
}

function AccountsContextLayout() {
  const [data, dispatch] = useReducer(accountReducer, {
    accounts: [],
    pusher: null,
    color: localStorage.getItem('brandingColor') || DEFAULT_COLOR, // default color
    logo: localStorage.getItem('brandingLogo') || '' // default logo
  });
  const { accounts, pusher, color, logo } = data;

  useEffect(() => {
    getPermissions();

    const pusherInstance = new Pusher(pusherApiKey, {
      cluster: 'eu',
      forceTLS: true
    });

    const channel = pusherInstance.subscribe(
      `click_and_collect.admins.${getUserId()}.notifications`
    );

    channel.bind('added', (data: MessageFormat) => {
      console.log(JSON.stringify(data));
      showNotification(data.title, data.text);
    });

    dispatch({ type: 'setPusher', pusher: pusherInstance });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusherInstance.disconnect();
    };
  }, []);

  useEffect(() => {
    const { color } = data;
    document.documentElement.style.setProperty('--branding-color', color);
  }, [data, data.color]);

  return (
    <AccountsContext.Provider value={{ accounts, pusher, dispatch, color, logo }}>
      <Outlet />
    </AccountsContext.Provider>
  );
}

export function useAccounts(): ContextValue {
  const context = useContext(AccountsContext);

  if (context === null) {
    throw new Error('useAccounts must be used within a AccountsContextLayout');
  }

  return context;
}

export default AccountsContextLayout;

export type { IAccount };

export { AccountsContext };
