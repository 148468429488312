import React from 'react';
import 'styles/ButtonWithProgress.scss';

interface ButtonWithProgressProps {
  progress: number;
  isRunning?: boolean;
  startProgress?: () => void;
  resetProgress?: () => void;
  onClick: () => void;
}

const ButtonWithProgress: React.FC<ButtonWithProgressProps> = ({
  progress,
  isRunning,
  startProgress,
  resetProgress,
  onClick
}) => {
  return (
    <div className="confirm-button-auto-allocate" onClick={onClick}>
      <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      {'Confirm'}
    </div>
  );
};

export default ButtonWithProgress;
