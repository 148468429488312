import React from 'react';
import 'styles/customer/orderCancelled.scss';

import Layout from 'components/shared/layout';
import ErrorComponent from 'components/shared/error';
import { getStoredItem } from 'utils';

import orderIcon from 'assets/images/order-icon.svg';

export default function OrderCancelled() {
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';

  return (
    <Layout logoIcon={brandingLogo}>
      <div className="order-cancelled-info">
        <ErrorComponent img={orderIcon} heading="Your order is Cancelled!" />
      </div>
    </Layout>
  );
}
