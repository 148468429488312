import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'styles/admin/forceOpenLocker.scss';
import 'styles/openLockerAnimation.scss';

import Layout from 'components/shared/layout';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import { APIS, ROUTES } from 'constants/index';
import { post, get } from 'utils/networks';
import { LocationState } from 'types/locationState';
import ForceOpenLockerAnimation from './forceOpenLockerAnimation';

type status = 'locked' | 'processing' | 'unlocking' | 'unlocked';

export default function ForceOpenLocker() {
  const [status, setStatus] = useState<status>('locked');
  const [showButton, setShowButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const location: LocationState = useLocation();
  const timerRef = useRef([]);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const timerCleaner = timerRef.current;
  const intervalCleaner = intervalRef.current;
  const id = location.state?.id;
  const orderId = location.state?.orderId;
  const lockerId = location.state?.lockerId;
  const order = location.state?.order;
  const customerName = location.state?.customerName;
  const { logo } = useAccounts();

  const handleUnlock = async () => {
    const response = await post(APIS.admin.lockerForceOpen(id));
    if (!response.ok) {
      setStatus('locked');
    }
  };

  const checkStatus = async () => {
    const response = await get(APIS.admin.lockerForceOpenStatus(id));
    if (response.ok) {
      return response.data.opened;
    }
    return false;
  };

  useEffect(() => {
    id === undefined && navigate(-1);
    return () => {
      timerCleaner.map((val) => clearTimeout(val));
      clearInterval(intervalCleaner);
    };
  }, [timerCleaner, id, navigate, intervalCleaner]);

  return (
    <Layout
      lockerInfo={order}
      backIcon={status === 'locked'}
      customerName={customerName}
      orderId={orderId}
      logoIcon={logo}
      navigateToHome={status === 'locked'}>
      <div className="force-open-locker">
        <div className="locker-details">
          <div className="locker-num">{lockerId}</div>
          <div className="locker">Locker</div>
          <ForceOpenLockerAnimation
            status={status}
            timerRef={timerRef}
            intervalRef={intervalRef}
            setStatus={(value) => setStatus(value)}
            showButton={() => setShowButton(true)}
            handleUnlock={handleUnlock}
            checkStatus={checkStatus}
          />
        </div>

        {showButton && (
          <PrimaryButton onClick={() => navigate(ROUTES.admin.forceOpenLocker, { replace: true })}>
            Locker Opened
          </PrimaryButton>
        )}
      </div>
    </Layout>
  );
}
