import { getAuthToken, isUserLoggedIn, handleApiError } from './index';
// eslint-disable-next-line import/named
import axios, { AxiosResponse, AxiosError } from 'axios';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type Data = Record<string, unknown> | FormData;
export type Params = Record<string, string>;

export interface ResponseType extends AxiosResponse {
  ok: boolean;
  response?: any;
}

export interface ErrorType extends AxiosError {
  ok: boolean;
  data?: any;
}

async function http(
  method: HttpMethod,
  url: string,
  data?: Data,
  params?: Params,
  signal?: AbortSignal,
  preventErrors?: boolean
): Promise<ResponseType | ErrorType> {
  const options = {
    method,
    headers: {} as Record<string, string | boolean>,
    data,
    params: params,
    signal
  };

  if (isUserLoggedIn()) {
    options.headers.Authorization = `Bearer ${getAuthToken()}`;
  }

  try {
    const response: ResponseType = await axios(url, options);
    response.ok = true;

    if (!(response.status >= 200 && response.status <= 299)) {
      response.ok = false;
      handleApiError(response);
    }
    return response;
  } catch (e) {
    const error = e as ErrorType;
    error.ok = false;

    if (!signal?.aborted && !preventErrors) {
      handleApiError(error.response);
    }
    return error;
  }
}

async function get(
  url: string,
  data?: Data,
  params?: Params,
  signal?: AbortSignal,
  preventErrors?: boolean
): Promise<ResponseType | ErrorType> {
  return http('GET', url, data, params, signal, preventErrors);
}

async function del(
  url: string,
  data?: Data,
  params?: Params,
  signal?: AbortSignal,
  preventErrors?: boolean
): Promise<ResponseType | ErrorType> {
  return http('DELETE', url, data, params, signal, preventErrors);
}

async function post(
  url: string,
  data?: Data,
  params?: Params,
  signal?: AbortSignal,
  preventErrors?: boolean
): Promise<ResponseType | ErrorType> {
  return http('POST', url, data, params, signal, preventErrors);
}

async function put(
  url: string,
  data?: Data,
  params?: Params,
  signal?: AbortSignal,
  preventErrors?: boolean
): Promise<ResponseType | ErrorType> {
  return http('PUT', url, data, params, signal, preventErrors);
}

async function patch(
  url: string,
  data?: Data,
  params?: Params,
  signal?: AbortSignal,
  preventErrors?: boolean
): Promise<ResponseType | ErrorType> {
  return http('PATCH', url, data, params, signal, preventErrors);
}

export { get, del, post, put, patch };
