import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'styles/admin/contactUs.scss';
import { APIS } from 'constants/index';
import ChatTextIcon from 'assets/images/cs-chat-text-icon.svg';
import ClockIcon from 'assets/images/cs-clock-icon.svg';
import EnvelopIcon from 'assets/images/cs-envelope-icon.svg';
import PhoneIcon from 'assets/images/cs-phone-icon.svg';
import Layout from 'components/shared/layout';
import { get } from 'utils/networks';
import { convertTime, getCustomerToken, getStoredItem } from 'utils';
import { Settings } from 'types/settings';

const currentDay = (): number => {
  const date = new Date();
  return date.getDay();
};

const RegexPattern = /<div>(\s|(<br>))*<\/div>/gim;

export default function contactUs() {
  const [info, setInfo] = useState<Settings>();
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index') || '';
  const token = getCustomerToken(index);
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';

  const getContactUsInfo = useCallback(async () => {
    const response = await get(
      APIS.customer.contactUsSettings(token as string),
      undefined,
      undefined,
      undefined,
      true
    );
    if (response.ok) {
      setInfo(response.data?.settings);
    }
  }, [token]);

  useEffect(() => {
    token && getContactUsInfo();
  }, [getContactUsInfo, token]);

  return (
    <Layout backIcon={true} logoIcon={brandingLogo}>
      <div className="contact-us">
        <div className="heading"> {info?.subject} </div>
        <div className="card">
          <div className="icon">
            <img src={ClockIcon} alt="clock-icon" />
          </div>
          <div className="time-sheet">
            {info?.available_contact_time?.map(({ day, open_time, closing_time }, index) => (
              <React.Fragment key={index}>
                <span className={`${currentDay() === index ? 'title-bold' : ''}`}>{day}</span>
                <span className={`${currentDay() === index ? 'info' : ''}`}>
                  {convertTime(open_time)}-{convertTime(closing_time)}
                </span>
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="card" style={{ alignItems: 'center' }}>
          <div className="icon">
            <img src={PhoneIcon} alt="phone-icon" />
          </div>
          <div>
            <div className="title">Phone Number</div>
            <div className="info">{info?.phone_number}</div>
          </div>
        </div>

        <div className="card" style={{ alignItems: 'center' }}>
          <div className="icon">
            <img src={EnvelopIcon} alt="envelop-icon" />
          </div>
          <div>
            <div>Email</div>
            <div className="info">{info?.email}</div>
          </div>
        </div>

        {info?.message && !info?.message.match(RegexPattern) && (
          <div className="card">
            <div className="icon">
              <img src={ChatTextIcon} alt="message-icon" />
            </div>
            {info?.message && !info?.message.match(RegexPattern) && (
              <div>
                <div>Messages</div>
                <div dangerouslySetInnerHTML={{ __html: info?.message }} />
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}
