import { useRef, useEffect } from 'react';

export default function useBlurHandler(show: boolean, onClose: () => void) {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    function checkIfClickedOutside(e: MouseEvent) {
      if (show && ref?.current?.children && !ref.current?.contains(e.target as HTMLElement)) {
        onClose();
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [onClose, show]);

  return { ref };
}
