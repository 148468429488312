import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import queryString from 'query-string';
import 'styles/admin/selectLocker.scss';

import Layout from 'components/shared/layout';
import ErrorComponent from 'components/shared/error';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import ChevronRight from 'assets/images/chevron-right-icon.svg';
import TumbleweedIcon from 'assets/images/tumbleweed.gif';

import { TestIds } from 'utils/testing/testIds';
import { get } from 'utils/networks';
import { APIS, ROUTES } from 'constants/index';
import { LocationState } from 'types/locationState';
import { LockerTag, Location, OrderLocker } from 'types/order';
import { CustomSingleValue, CustomOption } from 'components/shared/LockerLocationSelect';
const { CHOOSE_LOCKER_SIZE } = TestIds;

interface OptionType {
  value: string;
  label: string;
}

export default function ChooseLocker() {
  const navigate = useNavigate();
  const [tags, setTags] = useState<LockerTag[]>([]);
  const [lockersCount, setLockersCount] = useState<number | undefined>();
  const location: LocationState = useLocation();
  const id = location.state?.id;
  const orderId = location.state?.orderId;
  const customerName = location.state?.customerName;
  const { logo } = useAccounts();
  const initialLocation: OptionType = {
    value: '',
    label: 'All locations'
  };
  const [selectedLocation, setSelectedLocation] = useState<OptionType>(initialLocation);
  const [locations, setLocations] = useState<OptionType[]>([]);
  const [showLocationSelect, setShowLocationSelect] = useState(false);

  const getLockersInfo = useCallback(
    async (location_uids?: string) => {
      let queryStringValue = {};

      if (location_uids == '') {
        queryStringValue = {
          order_id: id
        };
      } else {
        queryStringValue = {
          order_id: id,
          location_uids: [location_uids]
        };
      }
      const newParams = queryString.stringify(queryStringValue, {
        arrayFormat: 'bracket'
      });

      const response = await get(`${APIS.admin.lockers}?${newParams}`);

      if (response.ok) {
        setLockersCount(response.data.length);
        const tagCounts: { [key: string]: number } = {};

        response.data.forEach((locker: OrderLocker) => {
          if (locker.tags) {
            locker.tags.forEach((tag) => {
              if (tagCounts[tag]) {
                tagCounts[tag]++;
              } else {
                tagCounts[tag] = 1;
              }
            });
          }
        });

        const formattedTags = Object.keys(tagCounts).map((tag) => ({
          name: tag,
          count: tagCounts[tag].toString()
        }));
        setTags(formattedTags);
      }

      // get(APIS.admin.lockerTags(id)).then((response) => {
      //   if (response.ok) {
      //     setTags(response.data.tags);
      //     console.log('response.data.tags', response.data.tags);
      //   }
      // });
    },
    [id]
  );

  const getLocations = useCallback(async () => {
    const response = await get(APIS.admin.locations);

    if (response.ok) {
      setShowLocationSelect(response.data.length > 1);
      if (response.data.length === 1) {
        setLocations([
          ...response.data.map(({ uid, name }: Location) => ({
            value: uid,
            label: name
          }))
        ]);
        setSelectedLocation({
          value: response.data[0]['uid'],
          label: response.data[0]['name']
        });
      } else {
        setLocations([
          { value: '', label: 'All locations' },
          ...response.data.map(({ uid, name }: Location) => ({
            value: uid,
            label: name
          }))
        ]);
      }
    }
  }, []);

  const handleTagClick = () => {
    navigate(ROUTES.admin.selectLocker, {
      state: { tag: 'all lockers', id, orderId, customerName, selectedLocation: selectedLocation }
    });
  };

  useEffect(() => {
    if (!location.state?.orderId) {
      navigate(ROUTES.admin.loadOrders, { replace: true });
      return;
    }

    getLockersInfo(selectedLocation?.value);
  }, [location.state?.orderId, navigate, getLockersInfo, selectedLocation?.value]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  return (
    <Layout
      backIcon={true}
      lockerInfo={true}
      orderId={orderId}
      customerName={customerName}
      navigateToHome={true}
      logoIcon={logo}
      onBackClick={() => navigate(ROUTES.admin.loadOrders)}>
      <div className="select-locker">
        <Select
          options={locations}
          placeholder="Choose location"
          className={`sizes-dropdown`}
          value={selectedLocation}
          onChange={(e) => e && setSelectedLocation(e)}
          isSearchable={false}
          isDisabled={!showLocationSelect}
          components={
            showLocationSelect
              ? { SingleValue: CustomSingleValue, Option: CustomOption }
              : {
                  SingleValue: CustomSingleValue,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }
          }
        />
        {!!lockersCount && (
          <>
            <div className="heading"> Choose Locker Attribute </div>
            <div className="size" onClick={handleTagClick}>
              <div>
                <div> All Lockers </div>
                <div className="lockers-count">Lockers : {lockersCount}</div>
              </div>
              <img src={ChevronRight} alt="" />
            </div>

            {tags.map(({ name, count }) => (
              <div
                key={name}
                data-testid={`${CHOOSE_LOCKER_SIZE}_${name}`}
                className="size"
                onClick={() =>
                  navigate(ROUTES.admin.selectLocker, {
                    state: {
                      tag: name.toLowerCase(),
                      id,
                      orderId,
                      customerName,
                      selectedLocation: selectedLocation
                    }
                  })
                }>
                <div>
                  <div>{name}</div>
                  <div className="lockers-count">Lockers : {count}</div>
                </div>
                <img src={ChevronRight} alt="" />
              </div>
            ))}
          </>
        )}

        {lockersCount === 0 && (
          <ErrorComponent img={TumbleweedIcon} heading="No Lockers Available" />
        )}
      </div>
    </Layout>
  );
}
