import React from 'react';
import 'styles/searchInput.scss';

import SearchIcon from 'assets/images/search-icon-placeholder.svg';
import { TestIds } from 'utils/testing/testIds';

const { ADMIN_SEARCH_INPUT } = TestIds;
interface Props {
  placeholder: string;
  value: string;
  handleChange: (e: string) => void;
}

export default function SearchInput({ value, placeholder, handleChange }: Props) {
  return (
    <div className="search-input-component">
      <img src={SearchIcon} alt="" />
      <input
        data-testid={ADMIN_SEARCH_INPUT}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
}
