import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAdminRole, getCandCRemoteOpenEnabled } from 'utils';

export default function RoleBasedRoute({
  children,
  allowedRoles
}: {
  children: JSX.Element;
  allowedRoles: string;
}) {
  const userRole = getAdminRole();
  const remoteOpenEnabled = getCandCRemoteOpenEnabled();

  if (allowedRoles !== userRole || remoteOpenEnabled !== 'true') {
    return <Navigate to="/admin" />;
  }

  return children;
}
