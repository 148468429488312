import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import 'styles/customer/storeName.scss';
import PushToOpenGif from 'assets/images/push_to_open_locker.gif';
import AutoOpenGif from 'assets/images/auto_open_locker.gif';

import PrimaryButton from 'components/shared/primaryButton.tsx';
import GrayButton from 'components/shared/grayButton.tsx';
import Layout from 'components/shared/layout';

import { APIS, ORDER_STATUSES, ROUTES } from 'constants/index';
import { get } from 'utils/networks';
import { Order } from 'types/order';
import { TestIds } from 'utils/testing/testIds';
import { manageCustomerToken } from 'services/customerTokenService';

const { CUSTOMER_STORE_NAME_LOCKER_NO } = TestIds;
const { REACT_APP_API_URL } = process.env;

export default function StoreName() {
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const index = searchParams.get('index');
  const requireScanning = false;
  const [logo, setLogo] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [canUnlockUntil, setCanUnlockUntil] = useState('');

  const navigateUser = useCallback(
    (path: string) => {
      if (!index) {
        console.error('Index is null or undefined');
        return;
      }

      navigate({
        pathname: path,
        search: `?${createSearchParams({
          index: index.toString()
        })}`
      });
    },
    [index]
  );

  const handleConfirm = () => {
    if (!index) {
      console.error('Index is null or undefined');
      return;
    }

    if (requireScanning) {
      // we will have to pass index to this route as well,
      // and change definition like how to get token and order details
      navigateUser(ROUTES.customer.storeNameScan);
      return;
    }
    const targetPath = order?.age_verification_required
      ? ROUTES.customer.ageVerification
      : ROUTES.customer.openLockerCustomer;

    navigateUser(targetPath);
  };

  const getOrder = useCallback(async () => {
    const response = await get(APIS.customer.order(token as string));
    if (response.ok) {
      if (response.data?.branding && response.data.branding.logo && response.data.branding.color) {
        const brandingLogo = `${REACT_APP_API_URL}/${response.data.branding.logo}`;
        localStorage.setItem('customerBrandingLogo', brandingLogo);
        document.documentElement.style.setProperty(
          '--branding-color',
          response.data.branding.color
        );
        setLogo(brandingLogo);
      } else {
        localStorage.removeItem('customerBrandingLogo');
      }
      if (response.data.status.toLowerCase() === ORDER_STATUSES.completed) {
        localStorage.setItem('customerOrder', JSON.stringify(response.data));
        navigateUser(ROUTES.customer.orderCollected);
        return;
      }

      setOrder(response.data);
      if (response.data.can_unlock_until) {
        const date = new Date(response.data.can_unlock_until);
        const time = date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
        setCanUnlockUntil(time);
      }
      localStorage.setItem('customerOrder', JSON.stringify(response.data));
    }
    setIsDataLoaded(true);
  }, [navigateUser, token]);

  useEffect(() => {
    manageCustomerToken(token, index, navigate);
  }, [token, index, navigate]);

  useEffect(() => {
    if (order) {
      const intervalId = setInterval(() => {
        if (order && order.can_unlock_until && order.opened_count === 0) {
          const canUnlockDate = new Date(order.can_unlock_until);
          const now = new Date();

          const timeDifference: any = canUnlockDate.getTime() - now.getTime();

          if (timeDifference > 0) {
            // Calculate remaining minutes and seconds
            const remainingMinutes = Math.floor(timeDifference / 60000); // 1 minute = 60000 ms
            const remainingSeconds = Math.floor((timeDifference % 60000) / 1000); // Remaining seconds

            // Log the remaining time
            console.log(
              `Time remaining: ${remainingMinutes} minutes and ${remainingSeconds} seconds`
            );
          }
          if (canUnlockDate < now) {
            if (order.status.toLowerCase() === ORDER_STATUSES.collecting) {
              navigateUser(ROUTES.customer.collectionWindowTimeOutPage);
            } else if (order.status.toLowerCase() === ORDER_STATUSES.completed) {
              if (!index) {
                console.error('Index is null or undefined');
                return;
              }
              let value = '';
              if (order?.feedback !== null) {
                value =
                  order?.feedback === 1 ? 'bad' : order?.feedback === 2 ? 'good' : 'excellent';
              }
              navigate(
                {
                  pathname: ROUTES.customer.orderComplete,
                  search: `?${createSearchParams({
                    index: index.toString()
                  })}`
                },
                { state: { experience: value } }
              );
            }
          }
        }
      }, 2900); // Check every 3 seconds

      return () => clearInterval(intervalId);
    }
  }, [order, navigate]);

  useEffect(() => {
    token && getOrder();
  }, [getOrder, token]);

  if (!isDataLoaded) {
    return <></>;
  }

  return (
    <div className="store-name-cont">
      <Layout
        lockerInfo={true}
        customerName={order?.customer_name}
        orderId={order?.order_id}
        logoIcon={logo}>
        <>
          {order &&
            order.enable_collection_window &&
            order.can_unlock_until &&
            order.status.toLowerCase() !== ORDER_STATUSES.completed && (
              <div className="timer-instructions">
                Your collection locker is available until {canUnlockUntil}
              </div>
            )}
          <div className="store-info">
            <div data-testid={CUSTOMER_STORE_NAME_LOCKER_NO} className="locker-num">
              {order?.locker?.locker_no}
            </div>
            <div className="locker">Locker</div>
            <div className="name">Hi {order?.customer_name?.split(' ')[0]}</div>
            <div className="store-name-text">
              {' '}
              Are you in {order?.account} - {order?.locker?.location}?
            </div>
            <div className="store-name-buttons-container">
              <GrayButton
                className="cancel-button"
                onClick={() => navigateUser(ROUTES.customer.customerErrorPage)}>
                No
              </GrayButton>
              <PrimaryButton className="yes-button" onClick={handleConfirm}>
                Yes
              </PrimaryButton>
            </div>
            {order && order.enable_collection_window && order.can_unlock_until === null && (
              <div className="instruction-container">
                Locker will be available for {order.collection_window_period} minutes. If this
                window lapses please use the link from collection message to restart.
              </div>
            )}
          </div>
          {order && order.locker_door_mode === 'push' && (
            <div className="help_gif">
              <img
                className="image"
                src={PushToOpenGif}
                onClick={() => navigateUser(ROUTES.customer.helpVideo)}
                aria-label="Gif showing how to collect the order"
              />
            </div>
          )}
          {order && order.locker_door_mode === 'pop' && (
            <div className="help_gif">
              <img
                className="image"
                src={AutoOpenGif}
                onClick={() => navigateUser(ROUTES.customer.helpVideo)}
                aria-label="Gif showing how to collect the order"
              />
            </div>
          )}
        </>
      </Layout>
    </div>
  );
}
