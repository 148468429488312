import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'constants/index';
import 'styles/errorPage.scss';

import Layout from 'components/shared/layout';
import PrimaryButton from 'components/shared/primaryButton.tsx';

import NotFoundIcon from 'assets/images/not-found-icon.svg';
import { getCustomerToken, getStoredItem } from 'utils';

export default function CustomerErrorPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index') || '';
  const customerToken = getCustomerToken(index);
  const tokenParam = customerToken ? `?token=${customerToken}` : '';
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';

  return (
    <Layout logoIcon={brandingLogo}>
      <div className="error-page-cont">
        <img className="not-found-icon" src={NotFoundIcon} alt="not found" />
        <p className="heading font-poppins"> Please go to store </p>
        <p className="error font-poppins">
          To collect your order, you need to be in store and in front of your locker. Please use the
          same link when you&apos;re in store.
        </p>
        <PrimaryButton onClick={() => navigate(`${ROUTES.customer.storeName}${tokenParam}`)}>
          Try Again
        </PrimaryButton>
      </div>
    </Layout>
  );
}
