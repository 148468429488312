import React from 'react';
import 'styles/errorPage.scss';

import Layout from 'components/shared/layout';

import NotFoundIcon from 'assets/images/not-found-icon.svg';
import { getStoredItem } from 'utils';

export default function CustomerTimeOutPage() {
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';

  return (
    <Layout logoIcon={brandingLogo}>
      <div className="error-page-cont">
        <img className="not-found-icon" src={NotFoundIcon} alt="not found" />
        <p className="heading font-poppins"> Collection window expired </p>
        <p className="error font-poppins">
          Your collection window has expired, refer to collection email/sms for instructions
        </p>
      </div>
    </Layout>
  );
}
