import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ROUTES } from 'constants/index';
import './serviceWorkerRegistration';

import 'App.scss';
import StoreName from 'pages/customer/storeName';
import StoreNameScan from 'pages/customer/storeNameScan';
import AgeVerification from 'pages/customer/ageVerification';
import OrderComplete from 'pages/customer/orderComplete';
import OrderFeedback from 'pages/customer/orderFeedback';
import ScanQRCode from 'pages/customer/scanQRCode';
import CustomerErrorPage from 'pages/customer/errorPage/index';
import OrderCancelled from 'pages/customer/orderCancelled';
import OrderCollected from 'pages/customer/orderCollected';
import AdminDashboard from 'pages/admin/dashboard';
import AdminErrorPage from 'pages/admin/errorPage';
import ViewOrders from 'pages/admin/viewOrders';
import IndividualOrder from 'pages/admin/individualOrder';
import LoadOrders from 'pages/admin/loadOrders';
import Lockers from 'pages/admin/lockers';
import ForceOpenLocker from 'pages/admin/forceOpenLocker';
import SelectLocker from 'pages/admin/selectLocker/index';
import ChooseLocker from 'pages/admin/selectLocker/chooseLocker';
import AutoAllocateLocker from 'pages/admin/autoAllocateLocker';
import OpenLockerCustomer from 'pages/customer/openLocker';
import OpenLockerAdmin from 'pages/admin/openLocker';
import AgeVerificationAdmin from 'pages/admin/ageVerification';
import Login from 'pages/admin/dashboard/login';
import ChangePassword from 'pages/admin/dashboard/changePassword';
import SwitchAccount from 'pages/admin/dashboard/switchAccount';
import ForgotPassword from 'pages/admin/dashboard/forgotPassword';
import UpdatePassword from 'pages/admin/dashboard/updatePassword';
import CancelOrder from 'pages/admin/ageVerification/cancelOrder';
import ClearOutOrderList from 'pages/admin/clearOutOrder/list';
import ClearOutOrder from 'pages/admin/clearOutOrder';
import CreateOrder from 'pages/admin/createOrder';
import ContactUs from 'pages/customer/contactUs';
import CustomerTimeOutPage from 'pages/customer/timeOut';
import Tutorial from 'pages/customer/tutorial';

import AdminProtectedRoutes from 'components/admin/protectedRoutes';
import CustomerProtectedRoutes from 'components/customer/protectedRoutes';
import AccountsContextLayout from 'components/admin/dashboard/accountsContextLayout';
import RoleBasedRoute from 'components/admin/RoleBasedRoute';

function App() {
  return (
    <div className="App">
      <div className="mobile-view">
        <BrowserRouter>
          <Routes>
            <Route element={<AccountsContextLayout />}>
              <Route path="/" element={<Login />} />
            </Route>
            <Route path="/customer">
              <Route index element={<StoreName />} />
              <Route
                path={ROUTES.customer.storeNameScan}
                element={
                  <CustomerProtectedRoutes>
                    <StoreNameScan />
                  </CustomerProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.customer.ageVerification}
                element={
                  <CustomerProtectedRoutes>
                    <AgeVerification />
                  </CustomerProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.customer.orderComplete}
                element={
                  <CustomerProtectedRoutes>
                    <OrderComplete />
                  </CustomerProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.customer.orderFeedback}
                element={
                  <CustomerProtectedRoutes>
                    <OrderFeedback />
                  </CustomerProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.customer.scanQRCode}
                element={
                  <CustomerProtectedRoutes>
                    <ScanQRCode />
                  </CustomerProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.customer.openLockerCustomer}
                element={
                  <CustomerProtectedRoutes>
                    <OpenLockerCustomer />
                  </CustomerProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.customer.contactUs}
                element={
                  <CustomerProtectedRoutes>
                    <ContactUs />
                  </CustomerProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.customer.orderCancelled}
                element={
                  <CustomerProtectedRoutes>
                    <OrderCancelled />
                  </CustomerProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.customer.orderCollected}
                element={
                  <CustomerProtectedRoutes>
                    <OrderCollected />
                  </CustomerProtectedRoutes>
                }
              />
              <Route path="*" element={<CustomerErrorPage />} />
              <Route
                path={ROUTES.customer.collectionWindowTimeOutPage}
                element={
                  <CustomerProtectedRoutes>
                    <CustomerTimeOutPage />
                  </CustomerProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.customer.helpVideo}
                element={
                  <CustomerProtectedRoutes>
                    <Tutorial />
                  </CustomerProtectedRoutes>
                }
              />
            </Route>
            <Route path="/admin" element={<AccountsContextLayout />}>
              <Route
                index
                element={
                  <AdminProtectedRoutes>
                    <AdminDashboard />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.changePassword}
                element={
                  <AdminProtectedRoutes>
                    <ChangePassword />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.switchAccount}
                element={
                  <AdminProtectedRoutes>
                    <SwitchAccount />
                  </AdminProtectedRoutes>
                }
              />
              <Route path={ROUTES.admin.forgotPassword} element={<ForgotPassword />} />
              <Route path={ROUTES.admin.updatePassword} element={<UpdatePassword />} />
              <Route
                path={ROUTES.admin.viewOrders}
                element={
                  <AdminProtectedRoutes>
                    <ViewOrders />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={`${ROUTES.admin.individualOrder}/:id`}
                element={
                  <AdminProtectedRoutes>
                    <IndividualOrder />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.createOrder}
                element={
                  <AdminProtectedRoutes>
                    <CreateOrder />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.loadOrders}
                element={
                  <AdminProtectedRoutes>
                    <LoadOrders />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.lockers}
                element={
                  <AdminProtectedRoutes>
                    <RoleBasedRoute allowedRoles={'EndCustomerAdmin'}>
                      <Lockers />
                    </RoleBasedRoute>
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.forceOpenLocker}
                element={
                  <AdminProtectedRoutes>
                    <RoleBasedRoute allowedRoles={'EndCustomerAdmin'}>
                      <ForceOpenLocker />
                    </RoleBasedRoute>
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.clearOutOrder}
                element={
                  <AdminProtectedRoutes>
                    <ClearOutOrder />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.clearOutOrderList}
                element={
                  <AdminProtectedRoutes>
                    <ClearOutOrderList />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.chooseLocker}
                element={
                  <AdminProtectedRoutes>
                    <ChooseLocker />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.selectLocker}
                element={
                  <AdminProtectedRoutes>
                    <SelectLocker />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.autoAllocateLocker}
                element={
                  <AdminProtectedRoutes>
                    <AutoAllocateLocker />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.openLockerAdmin}
                element={
                  <AdminProtectedRoutes>
                    <OpenLockerAdmin />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.admin.ageVerificationAdmin}
                element={
                  <AdminProtectedRoutes>
                    <AgeVerificationAdmin />
                  </AdminProtectedRoutes>
                }
              />
              <Route
                path={`${ROUTES.admin.cancelVerifiedOrder}/:id`}
                element={
                  <AdminProtectedRoutes>
                    <CancelOrder />
                  </AdminProtectedRoutes>
                }
              />
              <Route path="*" element={<AdminErrorPage />} />
            </Route>
            <Route path="*" element={<StoreName />} />
          </Routes>
        </BrowserRouter>

        <ToastContainer
          position="top-center"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          transition={Zoom}
          pauseOnHover
          theme="colored"
        />
      </div>
    </div>
  );
}

export default App;
