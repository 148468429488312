import React from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from 'constants/index';
import { isUserLoggedIn } from 'utils';

export default function AdminProtectedRoutes({ children }: { children: JSX.Element }): JSX.Element {
  const authenticated = isUserLoggedIn();
  return authenticated ? children : <Navigate to={ROUTES.admin.login} />;
}
