import React, { useState, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

import { TestIds } from 'utils/testing/testIds';

const { LOGIN_PASSWORD_EYE_ICON } = TestIds;
const eye = <FontAwesomeIcon icon={faEye} size="1x" color="grey" />;
const eyeSlash = (
  <FontAwesomeIcon data-testid={LOGIN_PASSWORD_EYE_ICON} icon={faEyeSlash} size="sm" color="grey" />
);

export default forwardRef<HTMLInputElement, any>(function PasswordField({ ...rest }, ref) {
  const [show, setShow] = useState<boolean>(false);

  const togglePasswordVisiblity = () => {
    setShow((currShow) => !currShow);
  };

  return (
    <div className="input-password">
      <input ref={ref} type={show ? 'text' : 'password'} {...rest} />
      {show && <i onClick={togglePasswordVisiblity}>{eye}</i>}
      {!show && <i onClick={togglePasswordVisiblity}>{eyeSlash}</i>}
    </div>
  );
});
