import React from 'react';
import 'styles/header.scss';

import MenuIcon from 'assets/images/menu-icon.svg';
import BackIcon from 'assets/images/back-icon-placeholder.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/index';

interface Props {
  menu?: boolean;
  backIcon?: boolean;
  menuOnClick?: () => void;
  onBackClick?: () => void;
  navigateToHome?: boolean;
  logoIcon: string;
}

export default function Header({
  menu,
  menuOnClick,
  backIcon,
  onBackClick,
  navigateToHome,
  logoIcon
}: Props) {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    if (navigateToHome) navigate(ROUTES.admin.dashboard);
  };

  return (
    <div className="header-cont">
      <div className="icon-space">
        {backIcon && (
          <img
            className="back-icon"
            src={BackIcon}
            onClick={() => (onBackClick ? onBackClick() : navigate(-1))}
          />
        )}
      </div>
      <img className="logo" src={logoIcon} onClick={handleLogoClick} />
      <div className="icon-space">
        {menu && <img className="menu-icon" src={MenuIcon} onClick={menuOnClick} alt="menu" />}
      </div>
    </div>
  );
}
