import React, { useEffect, useState } from 'react';
import 'styles/openLockerAnimation.scss';

import BlueLockIcon from 'assets/images/blue-lock-icon.svg';
import GreenLockIcon from 'assets/images/green-lock-icon.svg';
import OpenedLockIcon from 'assets/images/green-lock-opened-icon.svg';
import RedInfoIcon from 'assets/images/red-info-icon.svg';
import YellowInfoIcon from 'assets/images/yellow-info-icon.svg';
import { TestIds } from 'utils/testing/testIds';
import { ORDER_STATUSES } from 'constants/index';

const { OPEN_LOCKER_ERROR_MESSAGE, OPEN_LOCKER_INFO_MESSAGE } = TestIds;

type status = 'locked' | 'processing' | 'unlocking' | 'unlocked';

interface Props {
  status: status;
  errorMessage?: string;
  infoMessage?: string;
  timerRef: React.RefObject<ReturnType<typeof setTimeout>[]>;
  intervalRef: React.MutableRefObject<ReturnType<typeof setInterval> | undefined>;
  showButton?: () => void;
  setStatus: (a: status) => void;
  handleUnlock?: () => void;
  checkStatus?: () => Promise<string>;
  orderStatus?: string;
  collectionWindowEnabled?: boolean;
}

export default function OpenLockerAnimation({
  status,
  errorMessage,
  infoMessage,
  timerRef,
  intervalRef,
  showButton,
  setStatus,
  handleUnlock,
  checkStatus,
  orderStatus,
  collectionWindowEnabled
}: Props) {
  const [lockedIcon, setLockedIcon] = useState<string>(GreenLockIcon);
  const [errorMessageToShow, setErrorMessageToShow] = useState(errorMessage || '');

  const unlockLocker = () => {
    timerRef.current &&
      timerRef.current.push(
        setTimeout(() => {
          setStatus('unlocked');
          showButton && showButton();
        }, 3000)
      );
  };

  const updateLockedIcon = () => {
    timerRef.current &&
      timerRef.current.push(
        setTimeout(() => {
          setLockedIcon(OpenedLockIcon);
          unlockLocker();
        }, 1000)
      );
  };

  const lockerUnlocked = () => {
    setStatus('processing');
    if (
      orderStatus &&
      ![ORDER_STATUSES.loading, ORDER_STATUSES.collecting].includes(orderStatus.toLowerCase())
    ) {
      handleUnlock && handleUnlock();
    }

    if (intervalRef && checkStatus) {
      intervalRef.current = setInterval(async () => {
        const status = await checkStatus();
        if (status === 'success') {
          setStatus('unlocking');
          updateLockedIcon();
          clearInterval(intervalRef.current);
        } else if (status === 'error') {
          setStatus('locked');
          setErrorMessageToShow('Failed to unlock. Please try again.');
          clearInterval(intervalRef.current);
        }
      }, 1900);
    }
  };

  useEffect(() => {
    if (
      orderStatus &&
      [ORDER_STATUSES.loading, ORDER_STATUSES.collecting].includes(orderStatus.toLowerCase()) &&
      status == 'locked' &&
      !collectionWindowEnabled
    ) {
      lockerUnlocked();
    }
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (status === 'unlocked') {
      setLockedIcon(OpenedLockIcon);
    }
  }, [status]);

  return (
    <>
      {status === 'locked' && (
        <div className="open-locker-animation">
          <div className={`locker-icon ${errorMessageToShow !== '' ? 'mb-2' : 'mb-6'}`}>
            <img src={BlueLockIcon} alt="open locker" onClick={lockerUnlocked} />
            <div className="img-background blue-background heartbeat-effect-with-translate"></div>
            <div className=" img-background-with-opacity blue-background heartbeat-effect"></div>
          </div>
          <div className={['unlock-text', errorMessageToShow === '' ? 'mb-4' : ''].join(' ')}>
            Touch to Unlock
          </div>

          {errorMessageToShow && errorMessageToShow !== '' && (
            <div data-testid={OPEN_LOCKER_ERROR_MESSAGE} className="error-message massage-cont">
              <img src={RedInfoIcon} alt="" />
              <p className="message"> {errorMessageToShow} </p>
            </div>
          )}
        </div>
      )}

      {status === 'processing' && (
        <div className="open-locker-animation">
          <div className="circle-wrap">
            <div className="circle">
              <div className="mask full">
                <div className="fill"></div>
              </div>
              <div className="mask half">
                <div className="fill"></div>
              </div>
              <div className="inside-circle">
                <img src={BlueLockIcon} className="" alt="opening locker" />
              </div>
            </div>
          </div>
          <div className="unlock-text mb-4 opacity-3"> Touch to Unlock </div>
        </div>
      )}

      {(status === 'unlocking' || status === 'unlocked') && (
        <div className="open-locker-animation">
          <div className={`locker-icon ${infoMessage ? 'mb-2' : 'mb-6'}`}>
            <img src={lockedIcon} className={`forward-effect`} alt="locker opened" />
            {lockedIcon === OpenedLockIcon && (
              <div
                className={[
                  'img-background',
                  'green-background',
                  status === 'unlocking' ? 'heartbeat-effect-with-translate' : ''
                ].join(' ')}
              />
            )}
            {lockedIcon === OpenedLockIcon && (
              <div
                className={[
                  'img-background-with-opacity',
                  'green-background',
                  status === 'unlocking' ? 'heartbeat-effect' : ''
                ].join(' ')}
              />
            )}
          </div>

          {infoMessage && status === 'unlocked' ? (
            <div data-testid={OPEN_LOCKER_INFO_MESSAGE} className="info-message massage-cont">
              <img src={YellowInfoIcon} alt="" />
              <p className="message"> {infoMessage} </p>
            </div>
          ) : (
            <div
              className={['unlock-text', 'mb-4', status === 'unlocked' ? '' : 'opacity-3'].join(
                ' '
              )}>
              Door unlocked!
            </div>
          )}
        </div>
      )}
    </>
  );
}
