import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/header.scss';

import Logo from 'assets/images/dark-logo.svg';
import BackIcon from 'assets/images/back-icon.svg';

interface Props {
  logoIcon?: string;
}

export default function Header({ logoIcon = Logo }: Props) {
  const navigate = useNavigate();
  const brandingLogo = logoIcon === '' ? Logo : logoIcon;

  return (
    <div className="header-dark-cont">
      <img className="back-icon" src={BackIcon} onClick={() => navigate(-1)} />
      <img className="logo" src={brandingLogo} />
    </div>
  );
}
