import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'styles/admin/clearOutOrder.scss';
import 'styles/openLockerAnimation.scss';

import Layout from 'components/shared/layout';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import BlueLockIcon from 'assets/images/blue-lock-icon.svg';
import OpenedLockIcon from 'assets/images/green-lock-opened-icon.svg';
import GreenLockIcon from 'assets/images/green-lock-icon.svg';
import RedInfoIcon from 'assets/images/red-info-icon.svg';

import { APIS, ROUTES } from 'constants/index';
import { patch, get } from 'utils/networks';
import { LocationState } from 'types/locationState';

interface ClearOrderAnimationProps {
  status: status;
  errorMessage?: string;
  infoMessage?: string;
  timerRef: React.RefObject<ReturnType<typeof setTimeout>[]>;
  intervalRef: React.MutableRefObject<ReturnType<typeof setInterval> | undefined>;
  showButton?: () => void;
  setStatus: (a: status) => void;
  handleUnlock?: () => void;
  checkStatus?: () => Promise<string>;
}

type status = 'locked' | 'processing' | 'unlocking' | 'unlocked';

export default function ClearOutOrder() {
  const [status, setStatus] = useState<status>('locked');
  const [showButton, setShowButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const location: LocationState = useLocation();
  const timerRef = useRef([]);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const timerCleaner = timerRef.current;
  const intervalCleaner = intervalRef.current;
  const id = location.state?.id;
  const orderId = location.state?.orderId;
  const lockerNum = location.state?.lockerNum;
  const customerName = location.state?.customerName;
  const { logo } = useAccounts();

  const handleUnlock = async () => {
    const response = await patch(APIS.admin.clearOutOrder(id));
    if (!response.ok) {
      setStatus('locked');
    }
  };

  const checkStatus = async () => {
    const response = await get(APIS.admin.lockerStatus(id));
    if (response.ok) {
      return response.data.opened;
    }
    return 'pending';
  };

  useEffect(() => {
    id === undefined && navigate(-1);
    return () => {
      timerCleaner.map((val) => clearTimeout(val));
      clearInterval(intervalCleaner);
    };
  }, [timerCleaner, id, navigate, intervalCleaner]);

  return (
    <Layout
      lockerInfo={true}
      backIcon={status === 'locked'}
      customerName={customerName}
      orderId={orderId}
      logoIcon={logo}
      navigateToHome={status === 'locked'}>
      <div className="clear-out-order">
        <div className="clear-out-order-info">
          <div className="locker-num">{lockerNum}</div>
          <div className="locker">Locker</div>
          <ClearOrderAnimation
            status={status}
            timerRef={timerRef}
            intervalRef={intervalRef}
            setStatus={(value) => setStatus(value)}
            showButton={() => setShowButton(true)}
            handleUnlock={handleUnlock}
            checkStatus={checkStatus}
          />
        </div>

        {status === 'locked' && <PrimaryButton onClick={() => navigate(-1)}>Cancel</PrimaryButton>}

        {showButton && (
          <PrimaryButton
            onClick={() => navigate(ROUTES.admin.clearOutOrderList, { replace: true })}>
            Confirm Order is Cleared Out
          </PrimaryButton>
        )}
      </div>
    </Layout>
  );
}

const ClearOrderAnimation = ({
  status,
  timerRef,
  showButton,
  setStatus,
  handleUnlock,
  checkStatus,
  intervalRef
}: ClearOrderAnimationProps) => {
  const [lockedIcon, setLockedIcon] = useState<string>(GreenLockIcon);
  const statusRef = useRef(status);
  const [errorMessageToShow, setErrorMessageToShow] = useState('');
  statusRef.current = status;

  const unlockLocker = () => {
    timerRef.current &&
      timerRef.current.push(
        setTimeout(() => {
          if (statusRef.current === 'locked') return;

          setStatus('unlocked');
          showButton && showButton();
        }, 1500)
      );
  };

  const updateLockedIcon = () => {
    timerRef.current &&
      timerRef.current.push(
        setTimeout(() => {
          setLockedIcon(OpenedLockIcon);
          unlockLocker();
        }, 1500)
      );
  };

  const lockerUnlocked = () => {
    setStatus('processing');
    handleUnlock && handleUnlock();

    if (intervalRef && checkStatus) {
      intervalRef.current = setInterval(async () => {
        const status = await checkStatus();
        if (status === 'success') {
          setStatus('unlocking');
          updateLockedIcon();
          clearInterval(intervalRef.current);
        } else if (status === 'error') {
          setStatus('locked');
          setErrorMessageToShow('Failed to unlock. Please try again.');
          clearInterval(intervalRef.current);
        }
      }, 1900);
    }
  };

  return (
    <>
      {status === 'locked' && (
        <div className="open-locker-animation">
          <div className={`locker-icon ${errorMessageToShow !== '' ? 'mb-2' : 'mb-6'}`}>
            <img src={BlueLockIcon} alt="clear out" onClick={lockerUnlocked} />
            <div className="img-background blue-background heartbeat-effect-with-translate"></div>
            <div className=" img-background-with-opacity blue-background heartbeat-effect"></div>
          </div>
          <div className={`unlock-text ${errorMessageToShow === '' ? 'mb-4' : ''}`}>
            Touch to Clear Out
          </div>

          {errorMessageToShow && errorMessageToShow !== '' && (
            <div className="error-message massage-cont">
              <img src={RedInfoIcon} alt="" />
              <p className="message"> {errorMessageToShow} </p>
            </div>
          )}
        </div>
      )}

      {status === 'processing' && (
        <div className="open-locker-animation">
          <div className="circle-wrap">
            <div className="circle">
              <div className="mask full">
                <div className="fill"></div>
              </div>
              <div className="mask half">
                <div className="fill"></div>
              </div>
              <div className="inside-circle">
                <img src={BlueLockIcon} className="" alt="opening locker" />
              </div>
            </div>
          </div>
          <div className="unlock-text mb-4 opacity-3"> Touch to Clear Out </div>
        </div>
      )}

      {(status === 'unlocking' || status === 'unlocked') && (
        <div className="open-locker-animation">
          <div className={`locker-icon mb-6`}>
            <img src={lockedIcon} className={`forward-effect`} alt="order cleared out" />
            {lockedIcon === OpenedLockIcon && (
              <>
                <div
                  className={[
                    'img-background',
                    'green-background',
                    status === 'unlocking' ? 'heartbeat-effect-with-translate' : ''
                  ].join(' ')}
                />
                <div
                  className={[
                    'img-background-with-opacity',
                    'green-background',
                    status === 'unlocking' ? 'heartbeat-effect' : ''
                  ].join(' ')}
                />
              </>
            )}
          </div>

          <div
            className={[
              'unlock-text',
              'dark-text',
              'mb-4',
              status === 'unlocked' ? '' : 'opacity-3'
            ].join(' ')}>
            Door unlocked!
          </div>
        </div>
      )}
    </>
  );
};
