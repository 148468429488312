import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useSearchParams, createSearchParams } from 'react-router-dom';
import { APIS, ORDER_STATUSES, ROUTES } from 'constants/index';
import 'styles/customer/orderComplete.scss';

import goodExperience from 'assets/images/good-experience.svg';
import excellentExperience from 'assets/images/excellent-experience.svg';
import badExperience from 'assets/images/bad-experience.svg';
import orderIcon from 'assets/images/order-icon.svg';
import NeedHelp from 'assets/images/need-help-cs-icon.svg';

import { get, patch } from 'utils/networks';
import { LocationState } from 'types/locationState';
import { getCustomerToken, getStoredItem } from 'utils';

import Layout from 'components/shared/layout';
import { Order } from 'types/order';
import PrimaryButton from 'components/shared/primaryButton.tsx';

export default function OrderComplete() {
  const navigate = useNavigate();
  const location: LocationState = useLocation();
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index') || '';
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';
  // const experience = location.state?.experience || '';
  const [activeFeedback, setActiveFeedback] = useState<string>();
  const token = getCustomerToken(index);
  const [order, setOrder] = useState<Order>();
  const [customerNamer, setCustomerName] = useState();
  const [settingsEnabled, setSettingsEnabled] = useState<boolean>(false);
  const [canUnlockUntil, setCanUnlockUntil] = useState('');
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null); // Track interval
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [atEnd, setEnd] = useState(false);

  const navigateUser = (path: string) => {
    if (!index) {
      console.error('Index is null or undefined');
      return;
    }

    navigate({
      pathname: path,
      search: `?${createSearchParams({
        index: index.toString()
      })}`
    });
  };

  const handleExperience = async (value: 'excellent' | 'good' | 'bad') => {
    setActiveFeedback(value);
    const id = value === 'bad' ? '1' : value === 'good' ? '2' : '3';

    const response = await patch(
      APIS.customer.submitFeedback(token, id),
      undefined,
      undefined,
      undefined
    );
    if (response.ok) {
      // navigate(ROUTES.customer.orderFeedback, { state: { experience: value } });
      setFeedbackSubmitted(true);
    }
  };

  const getContactUsInfo = useCallback(async () => {
    const response = await get(
      APIS.customer.contactUsSettings(token as string),
      undefined,
      undefined,
      undefined,
      true
    );

    if (response.ok) {
      setSettingsEnabled(true);
    }
  }, [token]);

  const getOrder = useCallback(async () => {
    const response = await get(APIS.customer.order(token as string));
    if (response.ok) {
      if (response.data?.branding && response.data.branding.color) {
        document.documentElement.style.setProperty(
          '--branding-color',
          response.data.branding.color
        );
      }
      setOrder(response.data);
      let value = '';
      if (response.data.feedback !== null) {
        value =
          response.data.feedback === 1
            ? 'bad'
            : response.data.feedback === 2
            ? 'good'
            : 'excellent';
      }
      setActiveFeedback(value);
      setCustomerName(response.data.customer_name?.split(' ')[0]);
      if (response.data.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.active) {
        console.log('redirecting to time out page');
        navigateUser(ROUTES.customer.collectionWindowTimeOutPage);
      }
      if (response.data.can_unlock_until) {
        const date = new Date(response.data.can_unlock_until);
        const time = date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
        setCanUnlockUntil(time);
      }
    }
  }, [token]);

  const handleReopen = () => {
    navigateUser(ROUTES.customer.openLockerCustomer);
  };

  useEffect(() => {
    getContactUsInfo();
    getOrder();
  }, [getOrder, getContactUsInfo]);

  const getOrderAtTimeExpiry = () => {
    setEnd(true);
    setTimeout(() => {
      getOrder();
    }, 30000);
  };

  useEffect(() => {
    if (
      order &&
      order.can_unlock_until &&
      order.status.toLowerCase() === ORDER_STATUSES.collecting
    ) {
      const canUnlockDate = new Date(order.can_unlock_until);
      const now = new Date();

      if (canUnlockDate < now) {
        console.log('Unlock window has already expired. No need to set an interval.');
        return;
      }

      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      const checkUnlockWindow = () => {
        const now = new Date();
        const timeDifference: any = canUnlockDate.getTime() - now.getTime();

        if (timeDifference > 0) {
          // Calculate remaining minutes and seconds
          const remainingMinutes = Math.floor(timeDifference / 60000); // 1 minute = 60000 ms
          const remainingSeconds = Math.floor((timeDifference % 60000) / 1000); // Remaining seconds

          // Log the remaining time
          console.log(
            `Time remaining: ${remainingMinutes} minutes and ${remainingSeconds} seconds`
          );
        }

        if (canUnlockDate < now) {
          getOrderAtTimeExpiry();
          clearInterval(intervalRef.current!);
          if (order.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.active) {
            console.log('redirecting to time out page');
            navigateUser(ROUTES.customer.collectionWindowTimeOutPage);
          }
        }
      };

      intervalRef.current = setInterval(checkUnlockWindow, 3000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [order]);

  return (
    <>
      <Layout backIcon={true} logoIcon={brandingLogo}>
        <div className="order-complete-info">
          <div className={`${feedbackSubmitted ? 'feedback-sent-text' : 'content-heading'}`}>
            {feedbackSubmitted ? 'Feedback Sent!' : 'Thanks!'}
          </div>
          {!feedbackSubmitted && (
            <div className="content-heading mt-3">
              How was your experience today, {customerNamer}?
            </div>
          )}
          <div className="experience">
            <div
              className={['emoji', activeFeedback === 'bad' ? 'active' : ''].join(' ')}
              onClick={() => !feedbackSubmitted && handleExperience('bad')}>
              <img src={badExperience} alt="bad" />
            </div>
            <div
              className={['emoji', activeFeedback === 'good' ? 'active' : ''].join(' ')}
              onClick={() => !feedbackSubmitted && handleExperience('good')}>
              <img src={goodExperience} alt="good" />
            </div>
            <div
              className={['emoji', activeFeedback === 'excellent' ? 'active' : ''].join(' ')}
              onClick={() => !feedbackSubmitted && handleExperience('excellent')}>
              <img src={excellentExperience} alt="excellent" />
            </div>
          </div>
          {feedbackSubmitted && (
            <div className="feedback-text mt-4 mb-4 gray6">
              We love hearing from you! Thank you for leaving feedback for us.
            </div>
          )}
        </div>
        <>
          {order &&
          [ORDER_STATUSES.collecting].includes(order.status.toLowerCase()) &&
          order.enable_collection_window &&
          order.can_unlock_until &&
          new Date(order.can_unlock_until) > new Date() ? (
            <div className="order-info">
              <img src={orderIcon} />
              <PrimaryButton className="reopen-button" onClick={handleReopen}>
                Reopen
              </PrimaryButton>
              <div className="reopen-instructions mt-4 gray6">
                Your collection locker is available until {canUnlockUntil}.
              </div>
            </div>
          ) : (
            <>
              {order && order.status.toLowerCase() === ORDER_STATUSES.completed && (
                <div className="order-info">
                  <img src={orderIcon} />
                  <div className="order-heading">Your order is complete!</div>
                </div>
              )}
            </>
          )}
        </>
      </Layout>

      {settingsEnabled && (
        <PrimaryButton
          className="contact-us-button"
          onClick={() => navigateUser(ROUTES.customer.contactUs)}>
          {
            <>
              <img src={NeedHelp} alt="need-help-icon" />
              <span>
                Need help? <br /> Contact Us
              </span>
            </>
          }
        </PrimaryButton>
      )}
    </>
  );
}
