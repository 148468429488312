import React, { useEffect, useState } from 'react';
import 'styles/openLockerAnimation.scss';

import BlueLockIcon from 'assets/images/blue-lock-icon.svg';
import OpenedLockIcon from 'assets/images/green-lock-opened-icon.svg';
import GreenLockIcon from 'assets/images/green-lock-icon.svg';

interface ForceOpenLockerAnimationProps {
  status: status;
  errorMessage?: string;
  infoMessage?: string;
  timerRef: React.RefObject<ReturnType<typeof setTimeout>[]>;
  intervalRef: React.MutableRefObject<ReturnType<typeof setInterval> | undefined>;
  showButton?: () => void;
  setStatus: (a: status) => void;
  handleUnlock?: () => void;
  checkStatus?: () => Promise<boolean>;
}

type status = 'locked' | 'processing' | 'unlocking' | 'unlocked';

export default function ForceOpenLockerAnimation({
  status,
  timerRef,
  intervalRef,
  showButton,
  setStatus,
  handleUnlock,
  checkStatus
}: ForceOpenLockerAnimationProps) {
  const [lockedIcon, setLockedIcon] = useState<string>(GreenLockIcon);

  const unlockLocker = () => {
    timerRef.current &&
      timerRef.current.push(
        setTimeout(() => {
          setStatus('unlocked');
          showButton && showButton();
        }, 3000)
      );
  };

  const updateLockedIcon = () => {
    timerRef.current &&
      timerRef.current.push(
        setTimeout(() => {
          setLockedIcon(OpenedLockIcon);
          unlockLocker();
        }, 1000)
      );
  };

  const lockerUnlocked = () => {
    setStatus('processing');
    handleUnlock && handleUnlock();
    if (intervalRef && checkStatus) {
      intervalRef.current = setInterval(async () => {
        const status = await checkStatus();
        if (status) {
          setStatus('unlocking');
          updateLockedIcon();
          clearInterval(intervalRef.current);
        }
      }, 1900);
    }
  };

  useEffect(() => {
    if (status === 'unlocked') {
      setLockedIcon(OpenedLockIcon);
    }
  }, [status]);

  return (
    <>
      {status === 'locked' && (
        <div className="open-locker-animation">
          <div className={`locker-icon ${'mb-6'}`}>
            <img src={BlueLockIcon} alt="force open locker" onClick={lockerUnlocked} />
            <div className="img-background blue-background heartbeat-effect-with-translate"></div>
            <div className=" img-background-with-opacity blue-background heartbeat-effect"></div>
          </div>
          <div className="unlock-text mb-4">Touch to Unlock</div>
        </div>
      )}

      {status === 'processing' && (
        <div className="open-locker-animation">
          <div className="circle-wrap">
            <div className="circle">
              <div className="mask full">
                <div className="fill"></div>
              </div>
              <div className="mask half">
                <div className="fill"></div>
              </div>
              <div className="inside-circle">
                <img src={BlueLockIcon} className="" alt="opening locker" />
              </div>
            </div>
          </div>
          <div className="unlock-text mb-4 opacity-3"> Touch to Unlock </div>
        </div>
      )}

      {(status === 'unlocking' || status === 'unlocked') && (
        <div className="open-locker-animation">
          <div className={`locker-icon mb-6`}>
            <img src={lockedIcon} className={`forward-effect`} alt="order cleared out" />
            {lockedIcon === OpenedLockIcon && (
              <>
                <div
                  className={[
                    'img-background',
                    'green-background',
                    status === 'unlocking' ? 'heartbeat-effect-with-translate' : ''
                  ].join(' ')}
                />
                <div
                  className={[
                    'img-background-with-opacity',
                    'green-background',
                    status === 'unlocking' ? 'heartbeat-effect' : ''
                  ].join(' ')}
                />
              </>
            )}
          </div>

          <div
            className={[
              'unlock-text',
              'dark-text',
              'mb-4',
              status === 'unlocked' ? '' : 'opacity-3'
            ].join(' ')}>
            Door unlocked!
          </div>
        </div>
      )}
    </>
  );
}
