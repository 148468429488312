import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/admin/createOrder.scss';

import GrayButton from 'components/shared/grayButton.tsx';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import InputField from './InputField';
import OrderItemForm from './orderItemForm';

import { OrderItem } from 'types/order';
import { post } from 'utils/networks';
// eslint-disable-next-line import/namespace
import { APIS, ROUTES } from 'constants/index';
import { showSuccessMessage, uid } from 'utils/index';

type CreateOrderFormProps = {
  orderReference?: string;
  customerFirstName?: string;
  customerLastName?: string;
  customerPhone?: string;
};

export default function CreateOrderForm({
  orderReference = '',
  customerFirstName = '',
  customerLastName = '',
  customerPhone = ''
}: CreateOrderFormProps) {
  const navigate = useNavigate();
  const [loading, setLaoding] = useState(false);
  const orderReferenceRef = useRef<HTMLInputElement>(null);
  const customerIdRef = useRef<HTMLInputElement>(null);
  const customerEmailRef = useRef<HTMLInputElement>(null);
  const customerFirstNameRef = useRef<HTMLInputElement>(null);
  const customerLastNameRef = useRef<HTMLInputElement>(null);
  const customerPhoneRef = useRef<HTMLInputElement>(null);
  const ageVerificationRef = useRef<HTMLInputElement>(null);
  const emptyItem = { description: '', quantity: '', serial_number: '' };
  const [orderItems, setOrderItems] = useState([{ id: uid(), ...emptyItem }]);
  const [emailRequired, setEmailRequired] = useState(true);
  const [phoneRequired, setPhoneRequired] = useState(true);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      if (orderReferenceRef.current) {
        orderReferenceRef.current.value = orderReference;
      }
      if (customerFirstNameRef.current) {
        customerFirstNameRef.current.value = customerFirstName;
      }
      if (customerLastNameRef.current) {
        customerLastNameRef.current.value = customerLastName;
      }
      if (customerPhoneRef.current) {
        setPhone(customerPhone);
      }

      validateFields(email, customerPhone);
      setIsMounted(true);
    }
  }, [orderReference, customerFirstName, customerLastName, customerPhone]);

  const updateOrderItems = (item: OrderItem) => {
    setOrderItems(orderItems.map((ele) => (ele.id === item.id ? item : ele)));
  };

  const handleDelete = (id: string) => {
    setOrderItems(orderItems.filter((ele) => ele.id != id));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const emailValue = customerEmailRef.current?.value;
    const phoneValue = customerPhoneRef.current?.value;

    if (!emailValue && !phoneValue) {
      customerEmailRef.current?.setCustomValidity(
        'Please enter either this field or Customer Mobile Number.'
      );
      customerPhoneRef.current?.setCustomValidity(
        'Please enter either this field or Customer Email.'
      );
      customerEmailRef.current?.reportValidity();
      customerPhoneRef.current?.reportValidity();
      return;
    } else {
      customerEmailRef.current?.setCustomValidity('');
      customerPhoneRef.current?.setCustomValidity('');
    }
    setLaoding(true);

    const orderItemsCopy = JSON.parse(JSON.stringify(orderItems));
    const ItemsWithoutId = JSON.parse(JSON.stringify(orderItemsCopy)).map(
      (item: Partial<OrderItem>) => {
        delete item.id;
        return item;
      }
    );
    const orderItemsNotPresent = Object.values(ItemsWithoutId[0]).every((value) => value === '');

    let data = {};
    if (orderItemsNotPresent) {
      data = {
        order: {
          uid: orderReferenceRef.current?.value,
          customer_email: email,
          customer_uid: customerIdRef.current?.value,
          age_verification_required: ageVerificationRef.current?.checked,
          customer_first_name: customerFirstNameRef.current?.value,
          customer_last_name: customerLastNameRef.current?.value,
          customer_mobile_number: phone
        }
      };
    } else {
      data = {
        order: {
          uid: orderReferenceRef.current?.value,
          customer_email: email,
          customer_uid: customerIdRef.current?.value,
          age_verification_required: ageVerificationRef.current?.checked,
          customer_first_name: customerFirstNameRef.current?.value,
          customer_last_name: customerLastNameRef.current?.value,
          customer_mobile_number: phone,
          items: orderItemsCopy.map((item: Partial<OrderItem>) => {
            delete item.id;
            return item;
          })
        }
      };
    }

    const response = await post(APIS.admin.orders, data);

    if (response.ok) {
      showSuccessMessage('Order has been created.');
      navigate(ROUTES.admin.dashboard);
    }
    setLaoding(false);
  };

  const handlePhoneInput = (e: any) => {
    const newPhone = e.target.value;
    setPhone(newPhone);
    if (newPhone.length != 0) {
      setEmailRequired(false);
      setPhoneRequired(false);
      e.target.setCustomValidity('');
      customerEmailRef.current?.setCustomValidity('');
    } else {
      setEmailRequired(true);
      setPhoneRequired(true);
    }
    validateFields(email, newPhone);
  };

  const handleEmailInput = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (newEmail.length != 0) {
      setEmailRequired(false);
      setPhoneRequired(false);
      e.target.setCustomValidity('');
      customerPhoneRef.current?.setCustomValidity('');
    } else {
      setEmailRequired(true);
      setPhoneRequired(true);
    }
    validateFields(newEmail, phone);
  };

  const validateFields = (emailValue: string, phoneValue: string) => {
    if (!emailValue && !phoneValue) {
      customerEmailRef.current?.setCustomValidity(
        `Please enter either this field or Customer Mobile Number.`
      );
      customerPhoneRef.current?.setCustomValidity(
        `Please enter either this field or Customer Email.`
      );
    } else {
      customerEmailRef.current?.setCustomValidity('');
      customerPhoneRef.current?.setCustomValidity('');
      setEmailRequired(false);
      setPhoneRequired(false);
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <InputField
        id="orderReference"
        ref={orderReferenceRef}
        label="Order Reference *"
        placeholder="Enter Order Reference"
        required
      />
      <InputField
        id="customerID"
        ref={customerIdRef}
        label="Customer ID"
        placeholder="Enter Customer ID"
      />
      <InputField
        id="customerEmail"
        ref={customerEmailRef}
        inputType="email"
        label="Customer Email *"
        placeholder="Enter Customer Email"
        value={email}
        required={emailRequired}
        onChange={handleEmailInput}
        onBlur={validateFields}
        onSelect={handleEmailInput}
      />
      <InputField
        id="customerFirstName"
        ref={customerFirstNameRef}
        label="Customer First Name"
        placeholder="Enter Customer First Name"
      />
      <InputField
        id="customerLastName"
        ref={customerLastNameRef}
        label="Customer Last Name"
        placeholder="Enter Customer Last Name"
      />
      <InputField
        id="customerPhone"
        ref={customerPhoneRef}
        label="Customer Mobile Number"
        placeholder="Enter Customer Mobile Number"
        value={phone}
        required={phoneRequired}
        onChange={handlePhoneInput}
        onBlur={validateFields}
        onSelect={handlePhoneInput}
      />
      <label id="ageVerification" className="age-verification-checkbox">
        <span className="label"> ID Verification Required </span>
        <input id="ageVerification" type="checkbox" ref={ageVerificationRef} />
        <span className="checkmark" />
      </label>

      <div className="order-items">
        <div className="heading"> Add Item </div>
        <div className="items-requirement"> You need to add at least 1 item </div>
        {orderItems.map((item, index) => (
          <OrderItemForm
            key={item.id}
            index={index}
            item={item}
            onChange={updateOrderItems}
            onDelete={handleDelete}
          />
        ))}
        <div
          className="add-item"
          onClick={() => setOrderItems([...orderItems, { id: uid(), ...emptyItem }])}>
          Add Order Item
        </div>
      </div>

      <div className="buttons">
        <GrayButton loading={loading} onClick={() => navigate(-1)}>
          Dismiss
        </GrayButton>
        <PrimaryButton loading={loading} buttonType="submit">
          Create Order
        </PrimaryButton>
      </div>
    </form>
  );
}
