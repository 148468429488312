import React from 'react';
import 'styles/admin/statusDropdown.scss';

import useBlurHandler from 'components/shared/hooks/useBlurHandler';

import { TestIds } from 'utils/testing/testIds';

const { VIEW_ORDER_STATUS } = TestIds;

interface Props {
  show: boolean;
  selected: string;
  onClose: () => void;
  setSelected: (a: string) => void;
  statuses: string[];
}

export default function StatusDropdown({ show, onClose, selected, setSelected, statuses }: Props) {
  const { ref } = useBlurHandler(show, onClose);

  const checkMark = (
    <span className="checkmark">
      <div className="checkmark_stem"></div>
      <div className="checkmark_kick"></div>
    </span>
  );

  return (
    <div className="status-dropdown">
      <div ref={ref} className="dropdown">
        <div
          className={`d-flex justify-between mb-5 ${selected !== 'pending' ? 'half-opacity' : ''}`}
          onClick={() => setSelected('pending')}>
          <span className="bold-medium"> Pending Statuses </span>
          {selected === 'pending' && checkMark}
        </div>
        <div
          className={`d-flex justify-between mb-5 ${
            selected !== 'completed' ? 'half-opacity' : ''
          }`}
          onClick={() => setSelected('completed')}>
          <span className="bold-medium"> Completed Statuses </span>
          {selected === 'completed' && checkMark}
        </div>
        <div
          className={`d-flex justify-between mb-5 ${selected !== 'all' ? 'half-opacity' : ''}`}
          onClick={() => setSelected('all')}>
          <span className="bold-medium"> All Statuses </span>
          {selected === 'all' && checkMark}
        </div>

        {statuses.map((status, index) => (
          <div
            key={index}
            data-testid={`${VIEW_ORDER_STATUS}_${status.toLowerCase()}`}
            className={`d-flex justify-between mt-4 ${selected !== status ? 'half-opacity' : ''}`}
            onClick={() => setSelected(status)}>
            <span className="d-flex gap-12 ">
              <span className={`dot ${status.toLowerCase().replaceAll(' ', '-')}-dot`} />
              <span className="dropdown-status"> {status} </span>
            </span>
            {selected === status && checkMark}
          </div>
        ))}
      </div>
    </div>
  );
}
