/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import 'styles/admin/viewOrders.scss';

import ErrorComponent from 'components/shared/error';
import Layout from 'components/shared/layout';
import StatusDropdown from 'components/admin/viewOrders/statusDropdown';
import SearchInput from 'components/shared/searchInput';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import ChevronDownIcon from 'assets/images/chevron-down-icon.svg';
import TumbleweedIcon from 'assets/images/tumbleweed.gif';

import { Order } from 'types/order';
import { get, ResponseType, ErrorType } from 'utils/networks';
import { capitalizeFirstLetter, truncateText } from 'utils';
import { APIS, ROUTES } from 'constants/index';
import { TestIds } from 'utils/testing/testIds';

const {
  VIEW_ORDER_ID,
  VIEW_ORDER_CUSTOMER_NAME,
  VIEW_ORDER_STATUSES_DROPDOWN,
  VIEW_ORDER_ORDER_HEADING
} = TestIds;

const pendingStatuses = [
  'Ready to Load',
  'Loading',
  'Ready for Collection',
  'Require Verification',
  'Collecting',
  'Clear Out',
  'Clearing Out'
];
const listOfPendingStatuses = [0, 1, 2, 3, 4, 7, 8];
const completedStatuses = ['Collected', 'Cancelled'];

const extractValues = (arr1: any, arr2: any) => {
  return Object.entries(arr1)
    .filter(([key, val]) => arr2.includes(key))
    .map(([key, val]) => val);
};

const dropDownTitle = (selected: string) => {
  switch (selected) {
    case 'all':
      return 'All Statuses';
    case 'pending':
      return 'Pending Statuses';
    case 'completed':
      return 'Completed Statuses';
    default:
      return capitalizeFirstLetter(selected.replaceAll('_', ' '));
  }
};

function isResponseType(response: ResponseType | ErrorType): response is ResponseType {
  return (response as ResponseType).headers !== undefined;
}

export default function ViewOrders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('pending');
  const [statuses, setStatuses] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [ordersCount, setOrdersCount] = useState<any>(0);
  const [totalPagesCount, SetTotalPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nextPage, setNextPage] = useState<number>(0);
  const [ordersPerPage, setOrdersPerPage] = useState<number>(60);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const { logo } = useAccounts();

  const orderHeading = (ordersCount: number) => {
    if (ordersCount == 1) {
      return `1 Order`;
    }
    if (ordersCount > 1) {
      return `${ordersCount} Orders`;
    }
    if (ordersCount == 0 && !loaded) {
      return `0 Orders`;
    }
    return `No Order`;
  };

  const applyOrdersFilters = async (query: string, queryStatus?: string) => {
    const selectedStatus = Object.keys(statuses).filter((ele) => ele == queryStatus)[0];
    const status = statuses[selectedStatus];
    let statusArray: any[] = [];

    if (queryStatus === 'pending') {
      statusArray = extractValues(statuses, pendingStatuses);
    } else if (queryStatus === 'completed') {
      statusArray = extractValues(statuses, completedStatuses);
    } else {
      statusArray = status;
    }

    const toBeFormatted = { query: query, status: statusArray };
    const newParams = queryString.stringify(toBeFormatted, {
      arrayFormat: 'bracket'
    });

    const response: ResponseType | ErrorType = await get(`${APIS.admin.orders}?${newParams}`);
    if (isResponseType(response) && response.ok) {
      setOrdersCount(parseInt(response.headers['x-total'] || '0', 10));
      SetTotalPageCount(parseInt(response.headers['x-total-pages'] || '0', 10));
      setOrdersPerPage(parseInt(response.headers['x-per-page'] || '0', 10));
      setNextPage(parseInt(response.headers['x-next-page'] || '0', 10));
      setCurrentPage(parseInt(response.headers['x-page'] || '0', 10));
      setOrders(response.data);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounce((q: string, status: string) => applyOrdersFilters(q, status), 500),
    []
  );

  const loadMoreOrders = async () => {
    setLoading(true);
    const selectedStatus = Object.keys(statuses).filter((ele) => ele === selected)[0];
    const status = statuses[selectedStatus];
    const statusArray =
      selected === 'pending'
        ? extractValues(statuses, pendingStatuses)
        : selected === 'completed'
        ? extractValues(statuses, completedStatuses)
        : status;

    const toBeFormatted = {
      query: search,
      status: statusArray,
      page: nextPage,
      per_page: ordersPerPage
    };
    const newParams = queryString.stringify(toBeFormatted, {
      arrayFormat: 'bracket'
    });

    const response: ResponseType | ErrorType = await get(`${APIS.admin.orders}?${newParams}`);
    if (isResponseType(response) && response.ok) {
      setOrders((prevOrders) => [...prevOrders, ...response.data]);
      setCurrentPage(parseInt(response.headers['x-page'] || '0', 10));
      setNextPage(parseInt(response.headers['x-next-page'] || '0', 10));
      setLoading(false);
    }
  };

  const initData = useCallback(async () => {
    setLoaded(false);
    const params = queryString.stringify(
      { status: listOfPendingStatuses },
      {
        arrayFormat: 'bracket'
      }
    );
    let response: ResponseType | ErrorType = await get(`${APIS.admin.orders}?${params}`);
    if (isResponseType(response) && response.ok) {
      setOrdersCount(parseInt(response.headers['x-total'] || '0', 10));
      SetTotalPageCount(parseInt(response.headers['x-total-pages'] || '0', 10));
      setOrdersPerPage(parseInt(response.headers['x-per-page'] || '0', 10));
      setNextPage(parseInt(response.headers['x-next-page'] || '0', 10));
      setCurrentPage(parseInt(response.headers['x-page'] || '0', 10));
      setOrders(response.data);
      setSelected('pending');
      setLoaded(true);
      response = await get(APIS.admin.orderStatuses);
      response.ok && setStatuses(response.data);
    }
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <Layout
      backIcon={true}
      navigateToHome={true}
      logoIcon={logo}
      onBackClick={() => navigate(ROUTES.admin.dashboard)}>
      <div className="view-orders">
        <SearchInput
          value={search}
          placeholder="Search order by name, id..."
          handleChange={(e: string) => {
            setSearch(e);
            delayedSearch(e, selected);
          }}
        />

        <div className="orders">
          <div className="header">
            <span data-testid={VIEW_ORDER_ORDER_HEADING} className="orders-num">
              {orderHeading(ordersCount)}
            </span>
            <span
              data-testid={VIEW_ORDER_STATUSES_DROPDOWN}
              className="dropdown-cont"
              onClick={() => setDropdown(!dropdown)}>
              <span className="status-heading">{dropDownTitle(selected)}</span>
              <img src={ChevronDownIcon} alt="" className="chevron-down-icon" />
            </span>
          </div>
          {dropdown && (
            <StatusDropdown
              show={dropdown}
              onClose={() => setDropdown(!dropdown)}
              selected={selected}
              statuses={Object.keys(statuses)}
              setSelected={(value) => {
                setSelected(value);
                setDropdown(false);
                applyOrdersFilters(search, value);
              }}
            />
          )}

          {orders.map(({ id, customer_name, order_id, status }) => (
            <div
              className="order"
              key={id}
              onClick={() => navigate(`${ROUTES.admin.individualOrder}/${id}`)}>
              <span className="">
                <div data-testid={`${VIEW_ORDER_ID}_${id}`} className="name">
                  {truncateText(order_id, 15)}
                </div>
                <div data-testid={`${VIEW_ORDER_CUSTOMER_NAME}_${id}`} className="description">
                  {capitalizeFirstLetter(customer_name)}{' '}
                </div>
              </span>
              <span className={['status', status.toLowerCase().replaceAll(' ', '-')].join(' ')}>
                <span className="dot" /> {status}
              </span>
            </div>
          ))}
        </div>

        {!loaded && <ErrorComponent img={TumbleweedIcon} heading="Loading..." />}

        {totalPagesCount > 1 && currentPage < totalPagesCount && (
          <PrimaryButton className={`load-more`} loading={loading} onClick={loadMoreOrders}>
            Load More
          </PrimaryButton>
        )}
      </div>
    </Layout>
  );
}
