import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/index';
import 'styles/customer/storeName.scss';

import PrimaryButton from 'components/shared/primaryButton.tsx';
import GrayButton from 'components/shared/grayButton.tsx';
import Layout from 'components/shared/layout';

import ScanIcon from 'assets/images/scan-icon.svg';
import { Order } from 'types/order';
import { TestIds } from 'utils/testing/testIds';
import { getStoredItem } from 'utils';

const { CUSTOMER_STORE_NAME_LOCKER_NO } = TestIds;

export default function StoreName() {
  const navigate = useNavigate();
  const order = JSON.parse(localStorage.getItem('customerOrder') ?? '{}') as Order;
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';
  const brandingColor = order.branding.color;

  useLayoutEffect(() => {
    if (brandingColor) {
      document.documentElement.style.setProperty('--branding-color', brandingColor);
    }
    return () => {
      document.documentElement.style.removeProperty('--branding-color');
    };
  }, [brandingColor]);

  return (
    <div className="store-name-cont">
      <Layout
        lockerInfo={true}
        customerName={order.customer_name}
        orderId={order.order_id}
        logoIcon={brandingLogo}>
        <div className="store-info">
          <div data-testid={CUSTOMER_STORE_NAME_LOCKER_NO} className="locker-num">
            {order.locker?.locker_no}
          </div>
          <div className="locker">Locker</div>
          <div className="name">Hi {order.customer_name?.split(' ')[0]}</div>
          <div className="store-name-text"> Are you in store name? </div>
          <PrimaryButton
            className="primary-button"
            onClick={() => navigate(ROUTES.customer.scanQRCode)}>
            <img src={ScanIcon} />
            <span> Scan Code </span>
          </PrimaryButton>
          <GrayButton
            className="cancel-button"
            onClick={() => navigate(ROUTES.customer.customerErrorPage)}>
            Cancel
          </GrayButton>
        </div>
      </Layout>
    </div>
  );
}
