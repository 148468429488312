import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import Select from 'react-select';
import 'styles/admin/autoAllocateLocker.scss';
import ErrorComponent from 'components/shared/error';

import Layout from 'components/shared/layout';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';
import TumbleweedIcon from 'assets/images/tumbleweed.gif';

import { APIS, ROUTES } from 'constants/index';
import { LocationState } from 'types/locationState';
import { get, patch } from 'utils/networks';
import { LockerTag, Location, AutoAllocatedLocker } from 'types/order';
import { CustomSingleValue, CustomOption } from 'components/shared/LockerLocationSelect';
import ButtonWithProgress from 'components/shared/ButtonWithProgress';

interface OptionType {
  value: string;
  label: string;
}

export default function AutoAllocateLocker() {
  const [lockerTags, setLockerTags] = useState<OptionType[]>([]);
  const location: LocationState = useLocation();
  const navigate = useNavigate();
  const id = location.state?.id;
  const orderId = location.state?.orderId;
  const customerName = location.state?.customerName;
  const { logo } = useAccounts();
  const initialLocation: OptionType = {
    value: '',
    label: 'All locations'
  };
  const initialTag: OptionType = {
    value: 'all attributes',
    label: 'All Attributes'
  };
  const [selectedTag, setSelectedTag] = useState<OptionType>(initialTag);
  const [selectedLocation, setSelectedLocation] = useState<OptionType>(initialLocation);
  const [locations, setLocations] = useState<OptionType[]>([]);
  const [showLocationSelect, setShowLocationSelect] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [autoAllocatedLocker, setAutoAllocatedLocker] = useState<AutoAllocatedLocker | null>();
  const [progress, setProgress] = useState(100);
  const [isRunning, setIsRunning] = useState(false);

  const autoAllocateLocker = useCallback(async (tag: string, location_uids: string) => {
    let queryStringValue = {};
    const ids = [location_uids];
    if (location_uids !== '' || tag !== '') {
      queryStringValue = {
        tag: tag,
        location_uids: ids
      };
    }

    const newParams = queryString.stringify(queryStringValue, {
      arrayFormat: 'bracket'
    });
    const url =
      newParams === ''
        ? `${APIS.admin.autoAllocateLockerToOrder(id)}`
        : `${APIS.admin.autoAllocateLockerToOrder(id)}?${newParams}`;

    const response = await patch(url);

    if (response.ok) {
      setAutoAllocatedLocker(response.data);
      startProgress();
    } else if (!response.ok && response.response?.status === 404) {
      setAutoAllocatedLocker(null);
    }
    setLoaded(true);
  }, []);

  const getLockerTags = useCallback(async () => {
    const response = await get(APIS.admin.lockerTags(id));

    if (response.ok) {
      setLockerTags([
        { value: 'all attributes', label: 'All Attributes' },
        ...response.data.tags.map(({ name }: LockerTag) => ({
          value: name.toLowerCase(),
          label: name
        }))
      ]);
    }
  }, [id]);

  const getLocations = useCallback(async () => {
    const response = await get(APIS.admin.locations);
    if (response.ok) {
      setShowLocationSelect(response.data.length > 1);

      if (response.data.length === 1) {
        setLocations([
          ...response.data.map(({ uid, name }: Location) => ({
            value: uid,
            label: name
          }))
        ]);
        setSelectedLocation({
          value: response.data[0]['uid'],
          label: response.data[0]['name']
        });
      } else {
        setLocations([
          { value: '', label: 'All locations' },
          ...response.data.map(({ uid, name }: Location) => ({
            value: uid,
            label: name
          }))
        ]);
      }
    }
  }, []);

  const clearLocalStorage = () => {
    if (localStorage.previousPath || localStorage.orderID) {
      localStorage.removeItem('previousPath');
      localStorage.removeItem('orderID');
    }
  };

  const handleButtonClick = () => {
    navigate(ROUTES.admin.openLockerAdmin, {
      state: {
        id: location.state.id,
        orderId,
        customerName,
        lockerNum: autoAllocatedLocker?.locker_no,
        previousRoute: 'loadOrder'
      }
    });
  };

  const startProgress = () => {
    setIsRunning(true);
  };

  const resetProgress = () => {
    setIsRunning(false);
    setProgress(100);
  };

  useEffect(() => {
    if (isRunning && progress > 0) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => Math.max(prevProgress - 1.67, 0)); // 1.67 for 6s (100%/60s)
      }, 100); // Decrease every 100ms
      if (progress === 0) {
        setIsRunning(false); // Stop progress when complete
      }

      return () => clearInterval(interval);
    } else if (progress === 0) {
      setIsRunning(false);
      navigate(ROUTES.admin.openLockerAdmin, {
        state: {
          id: location.state.id,
          orderId,
          customerName,
          lockerNum: autoAllocatedLocker?.locker_no,
          previousRoute: 'loadOrder'
        }
      });
    }
  }, [isRunning, progress]);

  useEffect(() => {
    if (location.state?.orderId) {
      autoAllocateLocker(
        selectedTag.value === 'all attributes' ? '' : selectedTag.value,
        selectedLocation?.value || ''
      );
    }
  }, [autoAllocateLocker, location.state?.orderId, selectedTag.value, selectedLocation?.value]);

  useEffect(() => {
    if (location.state?.orderId) {
      getLockerTags();
      getLocations();
      return;
    }
    navigate(ROUTES.admin.loadOrders, { replace: true });
  }, [getLockerTags, location.state?.orderId, navigate, getLocations]);

  return (
    <Layout
      backIcon={true}
      lockerInfo={true}
      orderId={orderId}
      customerName={customerName}
      navigateToHome={true}
      logoIcon={logo}>
      <div className="select-locker">
        <div className="select-container">
          <div className={`location`}>
            <Select
              options={locations}
              placeholder="Choose location"
              className="sizes-dropdown"
              value={selectedLocation}
              onChange={(e) => {
                e && setSelectedLocation(e);
                if (e && e.value !== selectedLocation.value) {
                  resetProgress();
                }
              }}
              isSearchable={false}
              isDisabled={!showLocationSelect}
              components={
                showLocationSelect
                  ? { SingleValue: CustomSingleValue, Option: CustomOption }
                  : {
                      SingleValue: CustomSingleValue,
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null
                    }
              }
            />
          </div>
          <div className={`locker-tags`}>
            <Select
              options={lockerTags}
              placeholder="Choose Size"
              className="sizes-dropdown"
              value={selectedTag}
              onChange={(e) => {
                e && setSelectedTag(e);
                if (e && e.value !== selectedTag.value) {
                  resetProgress();
                }
              }}
              isSearchable={false}
            />
          </div>
        </div>

        {autoAllocatedLocker && (
          <>
            <div className="locker-details">
              <div className="locker-size">
                <span className="mr-2"> Size:</span>{' '}
                {autoAllocatedLocker.tags.length > 0
                  ? autoAllocatedLocker.tags.join(', ')
                  : 'UNKNOWN'}
              </div>
              <div className="locker-number">{autoAllocatedLocker.locker_no}</div>
              <div className="locker-location">
                <span className="mr-2"> Location:</span> {autoAllocatedLocker.location}
              </div>
            </div>
            <div className="auto-allocation-note">
              Following free locker has been automatically selected for you
            </div>
            <div className="confirm-button-container">
              <ButtonWithProgress progress={progress} onClick={handleButtonClick} />
            </div>
          </>
        )}
        {loaded && autoAllocatedLocker === null && (
          <ErrorComponent img={TumbleweedIcon} heading="No free Locker available sorry!" />
        )}
      </div>
    </Layout>
  );
}
