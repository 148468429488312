import React, { forwardRef } from 'react';

export default forwardRef<
  HTMLInputElement,
  {
    id: string;
    label: string;
    placeholder: string;
    inputType?: string;
    labelClass?: string;
    inputClass?: string;
    value?: any;
    onChange?: (e: any) => void;
    onInvalid?: (e: any) => void;
    onBlur?: (e: any) => void;
    [x: string]: any;
  }
>(function InputField(
  {
    id,
    label,
    placeholder,
    inputType,
    labelClass,
    inputClass,
    value,
    onChange,
    onInvalid,
    onBlur,
    ...rest
  },
  ref
) {
  return (
    <>
      <label htmlFor={id} className={labelClass ?? 'label'}>
        {label}
      </label>
      <input
        id={id}
        ref={ref}
        type={inputType ?? 'text'}
        placeholder={placeholder}
        className={inputClass ?? 'input'}
        value={value}
        onChange={onChange}
        onInvalid={onInvalid}
        onBlur={onBlur}
        {...rest}
      />
    </>
  );
});
