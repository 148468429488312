import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/admin/forgotPassword.scss';
import { toast } from 'react-toastify';

import Layout from 'components/shared/layout';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import { post } from 'utils/networks';
import { APIS, ROUTES } from 'constants/index';

export default function ForgotPassword() {
  const [loading, setLoading] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { logo } = useAccounts();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      email: emailRef.current?.value
    };
    setLoading(true);
    const response = await post(APIS.admin.forgotPassword, data);
    setLoading(false);

    if (response.ok) {
      (event.target as HTMLFormElement).reset();
      toast.info("we've send instruction to your email to reset your password.");
      navigate(ROUTES.admin.login);
    }
  };

  return (
    <Layout backIcon={true} logoIcon={logo}>
      <div className="forgot-password-form">
        <div className="heading"> Forgot Password </div>

        <form className="" onSubmit={handleSubmit}>
          <label htmlFor="email"> Email </label>
          <input
            id="email"
            ref={emailRef}
            name="email"
            type={'email'}
            placeholder="Enter Email Address"
            required
          />
          <PrimaryButton loading={loading} buttonType="submit">
            Send
          </PrimaryButton>
        </form>
      </div>
    </Layout>
  );
}
