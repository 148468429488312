import { createSearchParams } from 'react-router-dom';
import type { NavigateFunction } from 'react-router';
import { ROUTES } from 'constants/index';
import { setCustomerTokenWithExpiry } from 'utils';

export const manageCustomerToken = (
  token: string | null,
  index: string | null,
  navigate: NavigateFunction
) => {
  if (token && !index) {
    let highestIndex = -1;
    let existingIndex: number | null = null;

    cleanExpiredTokens();

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('customerToken_')) {
        const currentIndex = parseInt(key.split('_')[1], 10);

        if (!isNaN(currentIndex) && currentIndex > highestIndex) {
          highestIndex = currentIndex;
        }

        const tokenData = localStorage.getItem(key) || '';
        const { token: storedToken } = JSON.parse(tokenData);

        if (storedToken === token) {
          existingIndex = currentIndex;
        }
      }
    }

    if (existingIndex !== null) {
      navigate({
        pathname: ROUTES.customer.storeName,
        search: `?${createSearchParams({
          token: token,
          index: existingIndex.toString()
        })}`
      });
    } else {
      const nextIndex = highestIndex + 1;
      setCustomerTokenWithExpiry(token, nextIndex.toString());

      navigate({
        pathname: ROUTES.customer.storeName,
        search: `?${createSearchParams({
          token: token,
          index: nextIndex.toString()
        })}`
      });
    }
  }
};

export const cleanExpiredTokens = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith('customerToken_')) {
      const tokenData = localStorage.getItem(key);
      const { expiry } = tokenData ? JSON.parse(tokenData) : { expiry: null };

      if (expiry && Date.now() > expiry) {
        localStorage.removeItem(key);
      }
    }
  }
};
