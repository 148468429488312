import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import 'styles/admin/clearOutOrderList.scss';

import Layout from 'components/shared/layout';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import ErrorComponent from 'components/shared/error';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import { APIS, ORDER_STATUSES, ROUTES } from 'constants/index';
import { get, ResponseType, ErrorType } from 'utils/networks';
import { TestIds } from 'utils/testing/testIds';
import RightArrow from 'assets/images/right-arrow-white-icon.svg';
import TumbleweedIcon from 'assets/images/tumbleweed.gif';
import { Order } from 'types/order';
import { truncateText, isResponseType } from 'utils';

const { CLEAR_OUT_ORDER_LIST_CUSTOMER_NAME, CLEAR_OUT_ORDER_LIST_ORDER_ID } = TestIds;

export default function ClearOutOrderList() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<Order[]>([]);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const { logo } = useAccounts();
  const [totalPagesCount, setTotalPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nextPage, setNextPage] = useState<number>(0);
  const [ordersPerPage, setOrdersPerPage] = useState<number>(60);
  const [loading, setLoading] = useState<boolean>(false);

  const initData = useCallback(async () => {
    setLoading(true);
    const response: ResponseType | ErrorType = await get(APIS.admin.orders, undefined, {
      status: ORDER_STATUSES.clear_out
    });

    if (isResponseType(response) && response.ok) {
      if (response.data?.length > 0) {
        setOrders(response.data);
        setTotalPageCount(parseInt(response.headers['x-total-pages'] || '0', 10));
        setOrdersPerPage(parseInt(response.headers['x-per-page'] || '0', 10));
        setNextPage(parseInt(response.headers['x-next-page'] || '0', 10));
        setCurrentPage(parseInt(response.headers['x-page'] || '0', 10));
      } else {
        setIsListEmpty(true);
      }
      setLoading(false);
    }
  }, []);

  const loadMoreData = async () => {
    setLoading(true);
    const newParams = queryString.stringify({
      status: ORDER_STATUSES.clear_out,
      page: nextPage,
      per_page: ordersPerPage
    });

    const response: ResponseType | ErrorType = await get(`${APIS.admin.orders}?${newParams}`);

    if (isResponseType(response) && response.ok) {
      setOrders((prevOrders) => [...prevOrders, ...response.data]);

      setTotalPageCount(parseInt(response.headers['x-total-pages'] || '0', 10));
      setOrdersPerPage(parseInt(response.headers['x-per-page'] || '0', 10));
      setNextPage(parseInt(response.headers['x-next-page'] || '0', 10));
      setCurrentPage(parseInt(response.headers['x-page'] || '0', 10));

      if (!response.data?.length) {
        setIsListEmpty(true);
        setLoading(false);
        return;
      }
      setIsListEmpty(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <Layout
      backIcon={true}
      navigateToHome={true}
      logoIcon={logo}
      onBackClick={() => navigate(ROUTES.admin.dashboard)}>
      <div className="clear-out-order-list">
        {orders.map((order) => {
          const { id, order_id, customer_name, locker } = order;
          return (
            <div className="card" key={id}>
              <div data-testid={`${CLEAR_OUT_ORDER_LIST_ORDER_ID}_${id}`} className="order-no">
                {truncateText(order_id, 15)}
              </div>
              <div data-testid={`${CLEAR_OUT_ORDER_LIST_CUSTOMER_NAME}_${id}`} className="name">
                {customer_name}
              </div>
              <PrimaryButton
                onClick={() =>
                  navigate(ROUTES.admin.clearOutOrder, {
                    state: {
                      id: id,
                      orderId: order_id,
                      customerName: customer_name,
                      lockerNum: locker?.locker_no
                    }
                  })
                }>
                <span className="child">
                  <span> Clear Out </span>
                  <img src={RightArrow} alt="" />
                </span>
              </PrimaryButton>
            </div>
          );
        })}

        {isListEmpty && (
          <ErrorComponent
            img={TumbleweedIcon}
            heading="Clear Out List Empty"
            error="You’re all good. There is nothing to clear out."
          />
        )}

        {totalPagesCount > 1 && currentPage < totalPagesCount && (
          <PrimaryButton className={`load-more`} loading={loading} onClick={() => loadMoreData()}>
            Load More
          </PrimaryButton>
        )}
      </div>
    </Layout>
  );
}
