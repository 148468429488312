import { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getStoredItem, getCustomerToken } from 'utils';

import 'styles/customer/tutorial.scss';
import { get } from 'utils/networks';
import { Order } from 'types/order';
import { APIS } from 'constants/index';

import Layout from 'components/shared/layout';
import HelpVideoPushLocker from 'assets/videos/push_locker_tutorial_video.mp4';
import HelpVideoPopLocker from 'assets/videos/pop_locker_tutorial_video.mp4';

export default function Tutorial() {
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index') || '';
  const customerToken = getCustomerToken(index);
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [order, setOrder] = useState<Order>();

  const getOrder = useCallback(async () => {
    const response = await get(APIS.customer.order(customerToken as string));
    if (response.ok) {
      setOrder(response.data);
      setIsDataLoaded(true);
    }
  }, [customerToken]);

  useEffect(() => {
    if (customerToken) {
      getOrder();
    }
  }, []);

  const helpVideo = order?.locker_door_mode === 'push' ? HelpVideoPushLocker : HelpVideoPopLocker;

  return (
    <Layout logoIcon={brandingLogo} backIcon>
      <div className="tutorial_video">
        {isDataLoaded && (
          <video
            src={helpVideo}
            width="100%"
            height="auto"
            autoPlay
            muted
            preload="auto"
            playsInline
            aria-label="Tutorial video explaining how to collect the order"
            loop
            controls>
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </Layout>
  );
}
