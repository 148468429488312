export enum TestIds {
  VIEW_ORDER_STATUSES_DROPDOWN = 'view_order_statuses_dropdown',
  VIEW_ORDER_STATUS = 'view_order_status',
  VIEW_ORDER_ID = 'view_order_id',
  VIEW_ORDER_CUSTOMER_NAME = 'view_order_customer_name',
  VIEW_ORDER_ORDER_HEADING = 'view_order_order_heading',
  ADMIN_SEARCH_INPUT = 'admin_search_input',
  LOAD_ORDER_ID = 'load_order_id',
  LOAD_ORDER_CUSTOMER_NAME = 'load_order_customer_name',
  INDIVIDUAL_ORDER_ID = 'individual_order_id',
  INDIVIDUAL_ORDER_DATE = 'individual_order_date',
  INDIVIDUAL_ORDER_CUSTOMER_NAME = 'individual_order_customer_name',
  INDIVIDUAL_ORDER_CUSTOMER_EMAIL = 'individual_order_customer_email',
  INDIVIDUAL_ORDER_ITEM_DESCRIPTION = 'individual_order_item_description',
  INDIVIDUAL_ORDER_LOGS_NAME = 'individual_order_logs_name',
  SELECT_LOCKER = 'select_locker',
  OPEN_LOCKER_ID = 'open_locker_id',
  OPEN_LOCKER_ERROR_MESSAGE = 'open_locker_error_message',
  OPEN_LOCKER_INFO_MESSAGE = 'open_locker_info_message',
  AGE_VERIFICATION_ORDER_ID = 'age_verification_order_id',
  AGE_VERIFICATION_ORDER_CUSTOMER_NAME = 'age_verification_order_customer_name',
  AGE_VERIFICATION_VERIFY_NOW_BUTTON = 'age_verification_verify_now_button',
  LOGIN_PASSWORD_EYE_ICON = 'LOGIN_PASSWORD_EYE_ICON',
  LOGIN_FORGOT_PASSWORD_BUTTON = 'login_forgot_password_button',
  UPDATE_PASSWORD_MISMATCH_ERROR = 'update_password_mismatch_error',
  CHANGE_PASSWORD_MISMATCH_ERROR = 'change_password_mismatch_error',
  DASHBOARD_SCREEN = 'dashboard_screen',
  SIDE_MENU_DASHBOARD = 'side_menu_dashboard',
  SIDE_MENU_CHANGE_PASSWORD = 'side_menu_change_password',
  SIDE_MENU_LOGOUT = 'side_menu_logout',
  CHOOSE_LOCKER_SIZE = 'choose_locker_size',
  LAYOUT_ORDER_ID = 'layout_order_id',
  LAYOUT_CUSTOMER_NAME = 'layout_customer_name',
  CUSTOMER_STORE_NAME_LOCKER_NO = 'customer_store_name_locker_no',
  QR_CODE_SCANNER = 'qr_code_scanner',
  ORDER_FEEDBACK_COUPON_CODE = 'order_feedbkac_coupon_code',
  CLEAR_OUT_ORDER_LIST_ORDER_ID = 'clear_out_order_list_order_id',
  CLEAR_OUT_ORDER_LIST_CUSTOMER_NAME = 'clear_out_order_list_customer_name'
}
