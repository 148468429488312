/* eslint-disable import/named */
import { components, SingleValueProps, OptionProps } from 'react-select';
import LocationIcon from 'assets/images/gis--location-poi.svg';
import 'styles/admin/lockerLocationSelect.scss';

interface OptionType {
  value: string;
  label: string;
}
export const CustomSingleValue = (props: SingleValueProps<OptionType, false>) => (
  <components.SingleValue {...props} className="custom-single-value">
    <img src={LocationIcon} alt="Location" className="location-img" />
    {props.children}
  </components.SingleValue>
);

export const CustomOption = (props: OptionProps<OptionType, false>) => (
  <components.Option {...props} className="custom-option">
    {props.data.label}
  </components.Option>
);
