import React from 'react';
import 'styles/customer/orderCollected.scss';

import Layout from 'components/shared/layout';
import ErrorComponent from 'components/shared/error';
import { getStoredItem } from 'utils';

import OrderCollectedIcon from 'assets/images/order_collected_icon.png';
import { Order } from 'types/order';

export default function OrderCollected() {
  const order = JSON.parse(localStorage.getItem('customerOrder') ?? '{}') as Order;
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';

  return (
    <Layout logoIcon={brandingLogo}>
      <div className="order-collected-info">
        <ErrorComponent
          img={OrderCollectedIcon}
          heading={`Order ${order.order_id} has been collected`}
        />
      </div>
    </Layout>
  );
}
