import React, { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import 'styles/admin/updatePassword.scss';

import Layout from 'components/shared/layout';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import PasswordField from 'components/shared/form/passwordField';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import { ROUTES, APIS } from 'constants/index';
import { showSuccessMessage } from 'utils';
import { patch } from 'utils/networks';
import { TestIds } from 'utils/testing/testIds';

const { UPDATE_PASSWORD_MISMATCH_ERROR } = TestIds;

export default function UpdatePassword() {
  const navigate = useNavigate();
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { logo } = useAccounts();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newPasswordRef.current?.value !== confirmPasswordRef.current?.value) {
      setError('<li>New and Confirm passwords should be same</li>');
      return;
    }
    const data = {
      token,
      password: newPasswordRef.current?.value,
      password_confirmation: confirmPasswordRef.current?.value
    };
    setError('');
    setLoading(true);
    const response = await patch(APIS.admin.forgotPassword, data);
    setLoading(false);

    if (response.ok && response.data.result) {
      (event.target as HTMLFormElement).reset();
      showSuccessMessage(response.data.result);
      navigate(ROUTES.admin.login);
    }
    if (typeof response.data?.errors === 'string') {
      setError(`<li>${response.data.errors}</li>`);
      return;
    }
    if (response.data?.errors) {
      setError(`<li>${response.data.errors.join('</li><li>')}</li>`);
    }
  };

  return (
    <Layout backIcon={true} logoIcon={logo}>
      <form className="update-password-form" onSubmit={handleSubmit}>
        <div className="heading"> Update Password </div>
        <label htmlFor="newPassword"> New Password </label>
        <PasswordField
          id="newPassword"
          name="newPassword"
          placeholder="Enter New Password"
          ref={newPasswordRef}
          required
        />
        <label htmlFor="confirmPassword"> Confirm Password </label>
        <PasswordField
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Enter Confirm Password"
          ref={confirmPasswordRef}
          required
        />
        {error && (
          <div
            data-testid={UPDATE_PASSWORD_MISMATCH_ERROR}
            className="password-error"
            dangerouslySetInnerHTML={{ __html: `<ul>${error}</ul>` }}
          />
        )}
        <PrimaryButton loading={loading} buttonType="submit">
          Update Password
        </PrimaryButton>
      </form>
    </Layout>
  );
}
