import React, { useRef, useState, useEffect } from 'react';
import { post } from 'utils/networks';
import { APIS } from 'constants/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import GrayButton from 'components/shared/grayButton.tsx';
import PrimaryButton from 'components/shared/primaryButton.tsx';

type Props = {
  submitHandler: () => void;
  cancel: () => void;
  setCustomerName: (text: string) => void;
  setCustomerTelephoneNo: (text: string) => void;
  setCustomerOrderNo: (text: string) => void;
};

export default function OcrTextExtractor({
  submitHandler,
  setCustomerName,
  setCustomerTelephoneNo,
  setCustomerOrderNo,
  cancel
}: Props) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [orderNo, setOrderNo] = useState<string>();
  const [telePhoneNo, setTelePhoneNo] = useState<string>();
  const [name, setName] = useState<string>();
  const [startScan, setStartScan] = useState(false);
  const [textExtracted, setTextExtracted] = useState<boolean>(false);

  const initializeCamera = () => {
    const constraints = {
      video: { width: 1280, height: 720, facingMode: { exact: 'environment' } }
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();

          const track = stream.getVideoTracks()[0];
          const capabilities = track.getCapabilities();
          const settings = track.getSettings();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (capabilities.focusMode && capabilities.focusMode.includes('continuous')) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (settings.focusMode !== 'continuous') {
              track
                .applyConstraints({
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  advanced: [{ focusMode: 'continuous' }]
                })
                .then(() => {
                  console.log('Focus mode set to continuous');
                })
                .catch((error) => {
                  console.error('Error setting focus mode:', error);
                });
            }
          }
        }
      })
      .catch((err) => console.error('Error accessing the camera: ', err));
  };
  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  useEffect(() => {
    initializeCamera();
    return () => {
      stopCamera();
    };
  }, []);

  const handleSubmit = async (file: any) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await post(APIS.admin.ocrUpload, formData);

      if (response.ok) {
        console.log('response', response);
        setTelePhoneNo(response.data.telephone_no);
        setCustomerTelephoneNo(response.data.telephone_no);
        setName(response.data.name);
        setCustomerName(response.data.name);
        setOrderNo(response.data.order_no);
        setCustomerOrderNo(response.data.order_no);
        setTextExtracted(true);
      } else {
        console.log('Result not Okay');
        setTextExtracted(true);
      }
    } catch (error) {
      console.error('Error submitting image:', error);
    }
  };

  const handleCapture = () => {
    setTextExtracted(false);
    setName('');
    setOrderNo('');
    setTelePhoneNo('');
    if (videoRef.current && videoRef.current.srcObject) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            const imageUrl = URL.createObjectURL(blob);
            setImageSrc(imageUrl);
            setStartScan(true);
            stopCamera();
            const file = new File([blob], 'captured-image.png', { type: 'image/png' });
            handleSubmit(file);
          }
        }, 'image/png');
      }
    }
  };

  const handleRetry = () => {
    setStartScan(false);
    setTextExtracted(false);
    setName('');
    setOrderNo('');
    setTelePhoneNo('');
    setImageSrc(null);
    initializeCamera();
  };

  return (
    <div className="scan-container">
      {startScan && imageSrc ? (
        <div className="output-container">
          <img src={imageSrc} alt="Captured" height={300} className="image" />
          <div className="info-container">
            {!textExtracted ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faSpinner} pulse size="4x" color="grey" />
              </div>
            ) : (
              <>
                <div className="flex-container">
                  <span className="title">Name:</span>
                  {textExtracted && !name ? (
                    <span className="cannot-detect">Cannot detect. </span>
                  ) : (
                    <span> {name}</span>
                  )}
                </div>
                <div className="flex-container tile-margin-top">
                  <span className="title">Phone:</span>
                  {textExtracted && !telePhoneNo ? (
                    <span className="cannot-detect">Cannot detect.</span>
                  ) : (
                    <span> {telePhoneNo} </span>
                  )}
                </div>
                <div className="flex-container tile-margin-top">
                  <span className="title">Order:</span>
                  {textExtracted && !orderNo ? (
                    <span className="cannot-detect">Cannot detect.</span>
                  ) : (
                    <span> {orderNo} </span>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="output-buttons-container">
            <GrayButton loading={!textExtracted} onClick={cancel}>
              Cancel
            </GrayButton>
            <PrimaryButton loading={!textExtracted} onClick={handleRetry}>
              Retry
            </PrimaryButton>
            <PrimaryButton
              loading={!textExtracted}
              className="submit-button"
              onClick={submitHandler}>
              Ok
            </PrimaryButton>
          </div>
        </div>
      ) : (
        <>
          <div className="input-container">
            <div className="cam-container">
              <video
                autoPlay
                disablePictureInPicture={true}
                muted={true}
                playsInline
                ref={videoRef}
                className="cam"
              />
            </div>
            <div className="instruction-container">
              <p>
                Please bring name, telephone and order no in focus and as zoomed in as possible
                before pressing scan.
              </p>
            </div>
            <div className="scan-buttons-container">
              <GrayButton onClick={cancel}>Cancel</GrayButton>
              <PrimaryButton onClick={handleCapture}>Scan</PrimaryButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
