import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import Select from 'react-select';
import 'styles/admin/selectLocker.scss';
import ErrorComponent from 'components/shared/error';

import Layout from 'components/shared/layout';
import SearchInput from 'components/shared/searchInput';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import RightArrow from 'assets/images/right-arrow-white-icon.svg';
import TumbleweedIcon from 'assets/images/tumbleweed.gif';

import { APIS, ORDER_STATUSES, ROUTES } from 'constants/index';
import { LocationState } from 'types/locationState';
import { get, patch } from 'utils/networks';
import { capitalizeFirstLetter } from 'utils';
import { LockerTag, OrderLocker, Location } from 'types/order';
import { TestIds } from 'utils/testing/testIds';
import { CustomSingleValue, CustomOption } from 'components/shared/LockerLocationSelect';

const { SELECT_LOCKER } = TestIds;

interface OptionType {
  value: string;
  label: string;
}

interface Lockers extends OrderLocker {
  tag: string;
}

export default function SelectLocker() {
  const [search, setSearch] = useState<string>('');
  const [activeLocker, setActiveLocker] = useState<string>('');
  const [lockers, setLockers] = useState<Lockers[]>([]);
  const [lockerTags, setLockerTags] = useState<OptionType[]>([]);
  const location: LocationState = useLocation();
  const navigate = useNavigate();
  const id = location.state?.id;
  const orderId = location.state?.orderId;
  const customerName = location.state?.customerName;
  const { logo } = useAccounts();

  const tag: OptionType = {
    value: location.state?.tag ?? '',
    label: capitalizeFirstLetter(location.state?.tag ?? '')
  };
  const init = location.state?.selectedLocation;
  const [selectedTag, setSelectedTag] = useState<OptionType>(tag);
  const [selectedLocation, setSelectedLocation] = useState<OptionType>(init);
  const [locations, setLocations] = useState<OptionType[]>([]);
  const [showLocationSelect, setShowLocationSelect] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const allocateLocker = async (id: string, lockerNum: string) => {
    const data = new FormData();
    data.append('locker_id', id);
    const response = await patch(APIS.admin.allocateLockerToOrder(location.state.id), data);

    if (!response.ok && response.response?.status != 401) {
      setActiveLocker('');
      return;
    }
    if (response.ok) {
      navigate(ROUTES.admin.openLockerAdmin, {
        state: {
          id: location.state.id,
          orderId,
          customerName,
          lockerNum,
          previousRoute: 'loadOrder'
        }
      });
    } else if (!response.ok && response.response?.status == 401) {
      navigate(ROUTES.admin.loadOrders, { replace: true });
    }
  };

  const getLockers = useCallback(
    async (q: string, tag: string, location_uids: string) => {
      let queryStringValue = {};
      if (location_uids !== '') {
        const ids = [location_uids];
        queryStringValue = {
          order_id: id,
          q: q,
          tag: tag,
          location_uids: ids
        };
      } else {
        queryStringValue = {
          order_id: id,
          q,
          tag
        };
      }

      const newParams = queryString.stringify(queryStringValue, {
        arrayFormat: 'bracket'
      });
      const response = await get(`${APIS.admin.lockers}?${newParams}`);
      if (response.ok) {
        setLockers(
          response.data
            .sort((a: OrderLocker, b: OrderLocker) =>
              Number(a.locker_no) < Number(b.locker_no) ? -1 : 1
            )
            .map((locker: OrderLocker) => ({
              ...locker,
              tag: selectedTag.label
            }))
        );
        setLoaded(true);
      }
    },
    [id, selectedTag.label]
  );

  const getLockerTags = useCallback(async () => {
    const response = await get(APIS.admin.lockerTags(id));

    if (response.ok) {
      setLockerTags([
        { value: 'all lockers', label: 'All Lockers' },
        ...response.data.tags.map(({ name }: LockerTag) => ({
          value: name.toLowerCase(),
          label: name
        }))
      ]);
    }
  }, [id]);

  const getLocations = useCallback(async () => {
    const response = await get(APIS.admin.locations);

    if (response.ok) {
      setShowLocationSelect(response.data.length > 1);

      if (response.data.length === 1) {
        setLocations([
          ...response.data.map(({ uid, name }: Location) => ({
            value: uid,
            label: name
          }))
        ]);
        setSelectedLocation({
          value: response.data[0]['uid'],
          label: response.data[0]['name']
        });
      } else {
        setLocations([
          { value: '', label: 'All locations' },
          ...response.data.map(({ uid, name }: Location) => ({
            value: uid,
            label: name
          }))
        ]);
      }
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounce(
      (q: string, tag: string, location_uids?: string) => getLockers(q, tag, location_uids || ''),
      500
    ),
    [getLockers]
  );

  const clearLocalStorage = () => {
    if (localStorage.previousPath || localStorage.orderID) {
      localStorage.removeItem('previousPath');
      localStorage.removeItem('orderID');
    }
  };

  const revertOrderLocker = useCallback(async () => {
    const response = await get(APIS.admin.order(location.state.id as string));

    if (response.data.status.toLowerCase().replaceAll(' ', '_') == ORDER_STATUSES.new_pending) {
      await patch(APIS.admin.revertOrderLocker(location.state.id));
    } else {
      clearLocalStorage();
      navigate(ROUTES.admin.loadOrders, { replace: true });
    }

    clearLocalStorage();
  }, [location.state.id, navigate]);

  useEffect(() => {
    const previousPath = localStorage.getItem('previousPath');
    if (previousPath && previousPath.includes('/admin/locker/open')) {
      revertOrderLocker();
    }

    return () => {
      if (localStorage.previousPath || localStorage.orderID) {
        localStorage.removeItem('previousPath');
        localStorage.removeItem('orderID');
      }
    };
  }, [revertOrderLocker]);

  useEffect(() => {
    if (location.state?.orderId) {
      delayedSearch(
        search,
        selectedTag.value === 'all lockers' ? '' : selectedTag.value,
        selectedLocation?.value || ''
      );
    }
  }, [delayedSearch, location.state?.orderId, selectedTag.value, search, selectedLocation?.value]);

  useEffect(() => {
    if (location.state?.orderId) {
      getLockerTags();
      getLocations();
      return;
    }
    navigate(ROUTES.admin.loadOrders, { replace: true });
  }, [getLockerTags, location.state?.orderId, navigate, getLocations]);

  return (
    <Layout
      backIcon={true}
      lockerInfo={true}
      orderId={orderId}
      customerName={customerName}
      navigateToHome={true}
      logoIcon={logo}>
      <div className="select-locker">
        <SearchInput
          value={search}
          placeholder="Search Lockers by Locker Number"
          handleChange={(e: string) => setSearch(e)}
        />
        <div className="select-container">
          <div className={`location`}>
            <Select
              options={locations}
              placeholder="Choose location"
              className="sizes-dropdown"
              value={selectedLocation}
              onChange={(e) => e && setSelectedLocation(e)}
              isSearchable={false}
              isDisabled={!showLocationSelect}
              components={
                showLocationSelect
                  ? { SingleValue: CustomSingleValue, Option: CustomOption }
                  : {
                      SingleValue: CustomSingleValue,
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null
                    }
              }
            />
          </div>
          <div className={`locker-tags`}>
            <Select
              options={lockerTags}
              placeholder="Choose Size"
              className="sizes-dropdown"
              value={selectedTag}
              onChange={(e) => e && setSelectedTag(e)}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="locker-numbers">
          {lockers.map(({ id, locker_no, tag }: Lockers) => (
            <div
              key={id}
              data-testid={`${SELECT_LOCKER}_${id}`}
              className={['locker', activeLocker === locker_no ? 'active-locker' : ''].join(' ')}
              onClick={() => {
                if (activeLocker == '') {
                  setActiveLocker(locker_no);
                  allocateLocker(id, locker_no);
                }
              }}>
              <div className="locker-size">{tag}</div>
              <div className="locker-number">
                <span> {locker_no} </span>
                <img src={RightArrow} alt="" />
              </div>
            </div>
          ))}
        </div>
        {loaded && lockers.length === 0 && (
          <ErrorComponent img={TumbleweedIcon} heading="No Lockers Available" />
        )}
      </div>
    </Layout>
  );
}
