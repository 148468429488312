import React from 'react';

import Layout from 'components/shared/layout';
import ErrorComponent from 'components/shared/error';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

export default function AdminErrorPage() {
  const { logo } = useAccounts();
  return (
    <Layout logoIcon={logo}>
      <ErrorComponent
        heading="Network Issue"
        error="Unfortunately, your connection has dropped. Please reconnect to continue."
      />
    </Layout>
  );
}
