import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import 'styles/customer/openLocker.scss';

import Layout from 'components/shared/layout';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import OpenLockerAnimation from 'components/customer/openLockerAnimation';

import { APIS, ORDER_STATUSES, ROUTES } from 'constants/index';
import { Order } from 'types/order';
import { getCustomerToken } from 'utils';
import { get, patch } from 'utils/networks';
import { TestIds } from 'utils/testing/testIds';
import NeedHelp from 'assets/images/need-help-cs-icon.svg';

const { OPEN_LOCKER_ID } = TestIds;
const { REACT_APP_API_URL } = process.env;

export default function OpenLockerCustomer() {
  const [status, setStatus] = useState<'locked' | 'processing' | 'unlocking' | 'unlocked'>(
    'locked'
  );
  const [showButton, setShowButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const timerRef = useRef([]);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const timerCleaner = timerRef.current;
  const intervalCleaner = intervalRef.current;
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index') || '';
  const token = getCustomerToken(index);
  const [settingsEnabled, setSettingsEnabled] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [canUnlockUntil, setCanUnlockUntil] = useState('');
  const [statusRequestIP, setStatusRequestIP] = useState(false);
  const statusRequestIPRef = useRef<boolean>(false);
  const [orderRequestIP, setOrderRequestIP] = useState(false);
  const orderRequestIPRef = useRef<boolean>(false);
  const endTimeIntervalref = useRef<ReturnType<typeof setInterval> | null>(null);
  const [completeRequestIP, setCompleteRequestIP] = useState(false); // it is for multiwindow, and we will set this to true when unlock is called and false when navigating to anyother page
  const completeRequestIPRef = useRef<boolean>(false);
  const disableUnlockRef = useRef<boolean>(false);
  const [disableUnlock, setDisableUnlock] = useState<boolean>(false);

  const getContactUsInfo = useCallback(async () => {
    const response = await get(
      APIS.customer.contactUsSettings(token as string),
      undefined,
      undefined,
      undefined,
      true
    );

    if (response.ok) {
      setSettingsEnabled(true);
    }
  }, [token]);

  const navigateUserToFinalPage = (path: string) => {
    navigate({
      pathname: path,
      search: `?${createSearchParams({
        index: index.toString()
      })}`
    });
  };

  const getOrder = useCallback(async () => {
    setOrderRequestIP(true);
    const response = await get(APIS.customer.order(token as string));
    if (response.ok) {
      if (response.data?.branding && response.data.branding.logo && response.data.branding.color) {
        const brandingLogo = `${REACT_APP_API_URL}/${response.data.branding.logo}`;
        localStorage.setItem('customerBrandingLogo', brandingLogo);
        document.documentElement.style.setProperty(
          '--branding-color',
          response.data.branding.color
        );
        setLogo(brandingLogo);
      } else {
        localStorage.removeItem('customerBrandingLogo');
      }
      setOrder(response.data);
      if (response.data.can_unlock_until) {
        const date = new Date(response.data.can_unlock_until);
        const time = date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
        setCanUnlockUntil(time);
      }

      // basically this condition is for the time, when user comes to this page
      // and order status is collected!
      if (response.data.status.toLowerCase() === ORDER_STATUSES.completed) {
        if (response.data.enable_collection_window && !completeRequestIPRef.current) {
          localStorage.setItem('customerOrder', JSON.stringify(response.data));
          navigateUserToFinalPage(ROUTES.customer.orderCollected);
          return;
        } else if (response.data.enable_collection_window && completeRequestIPRef.current) {
          disableUnlockRef.current = true;
          if (!index) {
            console.error('Index is null or undefined');
            return;
          }

          setTimeout(() => {
            let value = '';
            if (response.data.feedback !== null) {
              value =
                response.data.feedback === 1
                  ? 'bad'
                  : response.data.feedback === 2
                  ? 'good'
                  : 'excellent';
            }

            navigate(
              {
                pathname: ROUTES.customer.orderComplete,
                search: `?${createSearchParams({
                  index: index.toString()
                })}`
              },
              { state: { experience: value } }
            );
          }, 3000);
        } else if (!response.data.enable_collection_window) {
          setStatus('unlocked');
          setShowButton(true);
        }
      }
      if (
        (response.data.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.active ||
          response.data.status.toLowerCase() === ORDER_STATUSES.collecting) &&
        response.data.opened_count === 0 &&
        response.data.enable_collection_window &&
        completeRequestIPRef.current &&
        response.data.can_unlock_until === null
      ) {
        disableUnlockRef.current = true;

        setTimeout(() => {
          navigateUserToFinalPage(ROUTES.customer.collectionWindowTimeOutPage);
        }, 3000);
      }
      setLoading(true);
    } else {
      navigate({
        pathname: ROUTES.customer.storeName,
        search: `?${createSearchParams({
          token: token,
          index: index.toString()
        })}`
      });
    }
    setOrderRequestIP(false);
    setIsDataLoaded(true);
  }, [token]);

  const handleSetStatusRequetsIp = (status: boolean) => {
    setStatusRequestIP(status);
  };

  useEffect(() => {
    statusRequestIPRef.current = statusRequestIP;
  }, [statusRequestIP]);

  useEffect(() => {
    orderRequestIPRef.current = orderRequestIP;
  }, [orderRequestIP]);

  useEffect(() => {
    completeRequestIPRef.current = completeRequestIP;
  }, [completeRequestIP]);

  useEffect(() => {
    disableUnlockRef.current = disableUnlock;
  }, [disableUnlock]);

  useEffect(() => {
    if (token) {
      getContactUsInfo();
      getOrder();
    }
  }, [getContactUsInfo, token, getOrder]);

  useEffect(() => {
    if (order && order.can_unlock_until && order.enable_collection_window) {
      const canUnlockDate = new Date(order.can_unlock_until);
      const now = new Date();

      const checkUnlockWindow = () => {
        const now = new Date();
        const timeDifference: any = canUnlockDate.getTime() - now.getTime();

        if (timeDifference > 0) {
          // Calculate remaining minutes and seconds
          const remainingMinutes = Math.floor(timeDifference / 60000); // 1 minute = 60000 ms
          const remainingSeconds = Math.floor((timeDifference % 60000) / 1000); // Remaining seconds

          // Log the remaining time
          console.log(
            `Time remaining: ${remainingMinutes} minutes and ${remainingSeconds} seconds`
          );
        }

        if (canUnlockDate < now) {
          console.log('widnow out now');
          if (!statusRequestIPRef.current && !orderRequestIPRef.current) {
            if (
              (order.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.active ||
                order.status.toLowerCase() === ORDER_STATUSES.collecting) &&
              order.opened_count === 0
            ) {
              console.log('redirecting to time out page');
              clearInterval(endTimeIntervalref.current!);
              setCompleteRequestIP(false);
              disableUnlockRef.current = true;
              setDisableUnlock(true);

              navigateUserToFinalPage(ROUTES.customer.collectionWindowTimeOutPage);
            } else if (
              [ORDER_STATUSES.collecting, ORDER_STATUSES.completed].includes(
                order.status.toLowerCase()
              ) &&
              order.opened_count > 0
            ) {
              console.log('redirecting to orderFeedback page');
              let value = '';
              if (order?.feedback !== null) {
                value =
                  order?.feedback === 1 ? 'bad' : order?.feedback === 2 ? 'good' : 'excellent';
              }

              clearInterval(endTimeIntervalref.current!);
              setCompleteRequestIP(false);
              navigate(
                {
                  pathname: ROUTES.customer.orderComplete,
                  search: `?${createSearchParams({
                    index: index.toString()
                  })}`
                },
                { state: { experience: value } }
              );
            }
          } else {
            console.log('Unlock request in progress. Not redirecting.');
          }
        }
      };

      // Set up the interval to run every 3 seconds
      endTimeIntervalref.current = setInterval(checkUnlockWindow, 3000);
    }

    return () => {
      if (endTimeIntervalref.current) {
        clearInterval(endTimeIntervalref.current);
      }
    };
  }, [order, navigate]);

  const handleUnlock = async () => {
    const response = await patch(APIS.customer.unlockLocker(token));
    setCompleteRequestIP(true);
    getOrder();
    if (!response.ok) {
      setStatus('locked');
    }
  };

  const checkStatus = async () => {
    const response = await get(APIS.customer.lockerStatus(token));
    if (response.ok && (response.data.unlock === 'success' || response.data.unlock === 'error')) {
      getOrder();
    }
    if (response.ok) {
      return response.data.unlock;
    }
    return 'pending';
  };

  const handleConfirmation = () => {
    let value = '';
    if (order?.feedback !== null) {
      value = order?.feedback === 1 ? 'bad' : order?.feedback === 2 ? 'good' : 'excellent';
    }
    navigate(
      {
        pathname: ROUTES.customer.orderComplete,
        search: `?${createSearchParams({
          index: index.toString()
        })}`
      },
      { state: { experience: value } }
    );
  };

  useEffect(() => {
    return () => {
      timerCleaner.map((val) => clearTimeout(val));
      clearInterval(intervalCleaner);
    };
  }, [timerCleaner, intervalCleaner]);

  if (!isDataLoaded) {
    return <></>;
  }

  return (
    <>
      <Layout
        lockerInfo={true}
        backIcon={status === 'locked'}
        customerName={order?.customer_name}
        orderId={order?.order_id}
        logoIcon={logo}>
        <>
          {order &&
            order.enable_collection_window &&
            order.can_unlock_until &&
            order.status.toLowerCase() !== ORDER_STATUSES.completed && (
              <div className="timer-instructions">
                Your collection locker is available until {canUnlockUntil}
              </div>
            )}
          {loading && (
            <div className="open-locker">
              <div className="locker-info">
                <div data-testid={OPEN_LOCKER_ID} className="locker-num">
                  {order?.locker?.locker_no}
                </div>
                <div className="locker">Locker</div>
                <OpenLockerAnimation
                  status={status}
                  timerRef={timerRef}
                  intervalRef={intervalRef}
                  errorMessage="Make sure that you are in front of the locker"
                  infoMessage="Push door to close locker"
                  setStatus={(value) => setStatus(value)}
                  showButton={() => setShowButton(true)}
                  handleUnlock={handleUnlock}
                  checkStatus={checkStatus}
                  orderStatus={order?.status}
                  collectionWindowEnabled={order?.enable_collection_window}
                  handleSetStatusRequetsIp={handleSetStatusRequetsIp}
                  disableUnlock={disableUnlock}
                />
              </div>

              {showButton && (
                <PrimaryButton className="confirm-button" onClick={handleConfirmation}>
                  Confirm Collection
                </PrimaryButton>
              )}
              {showButton && order && order.enable_collection_window && (
                <div className="open-next-provide-feedback">
                  Next, you can provide feedback and reopen locker{' '}
                </div>
              )}
            </div>
          )}
        </>
      </Layout>

      {settingsEnabled && loading && (
        <PrimaryButton
          className="contact-us-button"
          onClick={() =>
            navigate({
              pathname: ROUTES.customer.contactUs,
              search: `?${createSearchParams({
                index: index.toString()
              })}`
            })
          }>
          {
            <>
              <img src={NeedHelp} alt="need-help-icon" />
              <span>
                Need help? <br /> Contact Us
              </span>
            </>
          }
        </PrimaryButton>
      )}
    </>
  );
}
