import React, { useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import 'styles/customer/ageVerification.scss';

import Layout from 'components/shared/layout';
import WebPageIcon from 'assets/images/web-page-icon.svg';

import { get, patch } from 'utils/networks';
import { APIS, ROUTES, ORDER_STATUSES } from 'constants/index';
import { getCustomerToken, getStoredItem } from 'utils';
import { Order } from 'types/order';

export default function AgeVerification() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const order = JSON.parse(localStorage.getItem('customerOrder') ?? '{}') as Order;
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';
  const index = searchParams.get('index') || '';
  const token = getCustomerToken(index);
  const requestVerification = async (signal: AbortSignal) => {
    if (order.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.active) {
      await patch(APIS.customer.requestVerification(token), undefined, undefined, signal);
    }
  };

  const navigateUser = (path: string) => {
    if (!index) {
      console.error('Index is null or undefined');
      return;
    }

    navigate({
      pathname: path,
      search: `?${createSearchParams({
        index: index.toString()
      })}`
    });
  };

  const checkOrderStatus = useCallback(async () => {
    if (!token) return;

    const response = await get(APIS.customer.order(token), undefined, undefined, undefined, true);
    if (response.ok) {
      // order is reactivated
      if (
        response.data.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.active &&
        !response.data.verified_at
      ) {
        navigate({
          pathname: ROUTES.customer.storeName,
          search: `?${createSearchParams({
            token: token,
            index: index.toString()
          })}`
        });
      }

      if (order.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.active) {
        localStorage.setItem('customerOrder', JSON.stringify(response.data));
      }

      // order is completed
      if (response.data.status.toLowerCase() === ORDER_STATUSES.completed) {
        navigateUser(ROUTES.customer.orderComplete);
      }
      return;
    }
    // order is cancelled
    navigateUser(ROUTES.customer.orderCancelled);
  }, [navigate, token]);

  useEffect(() => {
    const controller = new AbortController();
    requestVerification(controller.signal);
    const interval = setInterval(checkOrderStatus, 2000);

    return () => {
      controller.abort();
      clearInterval(interval);
    };
  }, [checkOrderStatus]);

  return (
    <Layout
      lockerInfo={true}
      customerName={order.customer_name}
      orderId={order.order_id}
      logoIcon={brandingLogo}>
      <div className="age-verification-info">
        <img className="img" src={WebPageIcon} />
        <div className="content-heading">Your order requires ID verification</div>
        <p className="content-details">
          We have notified a store assistant and they will be with you soon.
        </p>
      </div>
    </Layout>
  );
}
