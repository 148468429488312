import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'constants/index';
import 'styles/customer/scanQRCode.scss';
import { getStoredItem } from 'utils';

import AdminErrorPage from 'pages/admin/errorPage';

import ErrorComponent from 'components/shared/error';
import Layout from 'components/shared/layout';
import useOnlineStatus from 'components/shared/hooks/useOnlineStatus';
import Header from 'components/customer/scanQRCode/header';
import HtmlQrcodePlugin from 'components/customer/scanQRCode/htmlQrcodePlugin';

import { Order } from 'types/order';

export default function ScanQRCode() {
  const navigate = useNavigate();
  const isOnline = useOnlineStatus();
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  const [cameraPermission, setCameraPermission] = useState<boolean>(!error ?? true);
  const order = JSON.parse(localStorage.getItem('customerOrder') ?? '{}') as Order;
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';

  const handleScan = (data: string) => {
    if (data && data == order.locker?.serial_number) {
      navigate(
        order?.age_verification_required
          ? ROUTES.customer.ageVerification
          : ROUTES.customer.openLockerCustomer
      );
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const RenderUI = cameraPermission ? (
    <div className="scan-qr-cont">
      <Header logoIcon={brandingLogo} />

      <HtmlQrcodePlugin
        setCameraPermission={(val: boolean) => setCameraPermission(val)}
        onScan={handleScan}
      />

      <div className="heading"> Scan QR Code </div>
    </div>
  ) : (
    <Layout logoIcon={brandingLogo}>
      <ErrorComponent
        heading="Camara is not accesible"
        error="Unfortunately we’re not able to access your camera, please check your permissions and try again."
      />
    </Layout>
  );

  return isOnline ? RenderUI : <AdminErrorPage />;
}
