import NewLogo from 'assets/images/new-small-logo.png';

const Notification = window.Notification || null;

if (!Notification) {
  console.log('HTML5 notifications are not supported.');
}

export function showNotification(title: string, content: any) {
  if (!Notification || Notification.permission !== 'granted') {
    return;
  }

  navigator.serviceWorker.ready.then((registration) => {
    registration
      .showNotification(title, {
        tag: title + content,
        body: content,
        icon: NewLogo,
        image: NewLogo,
        badge: NewLogo
      })
      .catch((error) => {
        console.error('Service Worker notification failed: ', error);
      });
  });
}

export function getPermissions() {
  if (Notification && Notification.permission === 'default') {
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        showNotification('Dear User!', 'This is how you will be notified!');
      }
    });
  }
}
