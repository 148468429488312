import React, { useEffect, useRef, useState, useCallback } from 'react';
import Layout from 'components/shared/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIS, ORDER_STATUSES, ROUTES } from 'constants/index';
import 'styles/customer/openLocker.scss';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import PrimaryButton from 'components/shared/primaryButton.tsx';
import OpenLockerAnimation from 'components/shared/openLockerAnimation';
import { LocationState } from 'types/locationState';
import { get, patch } from 'utils/networks';
import { TestIds } from 'utils/testing/testIds';
import { showSuccessMessage, showErrorMessage } from 'utils';

const { OPEN_LOCKER_ID } = TestIds;

export default function OpenLockerAdmin() {
  const [status, setStatus] = useState<'locked' | 'processing' | 'unlocking' | 'unlocked'>(
    'locked'
  );
  const [showButton, setShowButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const location: LocationState = useLocation();
  const timerRef = useRef([]);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const timerCleaner = timerRef.current;
  const intervalCleaner = intervalRef.current;
  const id = location.state?.id;
  const orderId = location.state?.orderId;
  const customerName = location.state?.customerName;
  const lockerNum = location.state?.lockerNum;
  const previousRoute = location.state?.previousRoute;
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState();
  const { logo } = useAccounts();

  const unlockLocker = async () => {
    const response = await patch(APIS.admin.unlockLocker(id));
    if (!response.ok) {
      navigate(ROUTES.admin.loadOrders, { state: { orderLoaded: true } });
    }
  };

  const checkStatus = async () => {
    const response = await get(APIS.admin.lockerStatus(id));
    if (response.ok) {
      return response.data.opened;
    }
    return 'pending';
  };

  const handleUnlock = async () => {
    if (previousRoute === 'loadOrder') {
      const response = await get(APIS.admin.order(id as string));
      if (response.ok) {
        if (
          response.data.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.new_pending
        ) {
          unlockLocker();
        } else {
          showErrorMessage('Invalid Order Status');
          navigate(ROUTES.admin.loadOrders, { replace: true });
        }
      }
    } else {
      unlockLocker();
    }
  };

  const clearLocalStorage = () => {
    if (localStorage.previousPath || localStorage.orderID) {
      localStorage.removeItem('previousPath');
      localStorage.removeItem('orderID');
    }
  };

  const handleButtonClick = () => {
    if (previousRoute === 'loadOrder') {
      showSuccessMessage('Order is Loaded');
    }
    if (previousRoute === 'ageVerification') {
      navigate(ROUTES.admin.ageVerificationAdmin, { replace: true });
    } else {
      navigate(ROUTES.admin.loadOrders, { state: { orderLoaded: true } });
    }
    clearLocalStorage();
  };

  const setOrderData = useCallback(async () => {
    const response = await get(APIS.admin.order(id as string));
    if (response.ok) {
      if (
        previousRoute === 'loadOrder' &&
        response.data.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.active
      ) {
        setStatus('unlocked');
        setShowButton(true);
      } else if (
        previousRoute === 'ageVerification' &&
        response.data.status.toLowerCase() === ORDER_STATUSES.completed
      ) {
        setStatus('unlocked');
        setShowButton(true);
      }
      setOrderStatus(response.data.status);
      setLoading(true);
    } else {
      clearLocalStorage();
      navigate(ROUTES.admin.loadOrders, { replace: true });
    }
  }, [id, previousRoute, navigate]);

  useEffect(() => {
    !location.state?.orderId && navigate(ROUTES.admin.loadOrders, { replace: true });
    setOrderData();

    localStorage.setItem('previousPath', window.location.pathname);
    localStorage.setItem('orderID', location.state?.id);

    return () => {
      timerCleaner.map((val) => clearTimeout(val));
      clearInterval(intervalCleaner);
    };
  }, [
    intervalCleaner,
    location.state?.id,
    location.state?.orderId,
    navigate,
    setOrderData,
    timerCleaner
  ]);

  return (
    <Layout
      lockerInfo={true}
      backIcon={status === 'locked'}
      orderId={orderId}
      customerName={customerName}
      logoIcon={logo}
      navigateToHome={status === 'locked'}>
      <>
        {loading && (
          <div className="open-locker">
            <div className="locker-info">
              <div data-testid={OPEN_LOCKER_ID} className="locker-num">
                {lockerNum}
              </div>
              <div className="locker">Locker</div>
              <OpenLockerAnimation
                status={status}
                timerRef={timerRef}
                intervalRef={intervalRef}
                setStatus={(value) => setStatus(value)}
                showButton={() => setShowButton(true)}
                handleUnlock={handleUnlock}
                checkStatus={checkStatus}
                orderStatus={orderStatus}
              />
            </div>

            {showButton && (
              <PrimaryButton className="confirm-button" onClick={handleButtonClick}>
                {previousRoute === 'ageVerification'
                  ? 'Order is Collected'
                  : 'Confirm Order is Loaded'}
              </PrimaryButton>
            )}
          </div>
        )}
      </>
    </Layout>
  );
}
