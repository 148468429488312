import React, { useCallback, useEffect, useRef, useState, RefObject } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from 'components/shared/layout';
import 'styles/admin/individualOrder.scss';

import VectorIcon from 'assets/images/vector-icon.svg';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import { get, patch } from 'utils/networks';
import { showSuccessMessage, truncateText } from 'utils';
import { Order, OrderLog, Status } from 'types/order';
import { APIS, ROUTES } from 'constants/index';

const LoadedStatus = ['Ready for Collection', 'Collecting', 'Collected'];
const ExcludedCancelStatus = ['Cancelled', 'Collected', 'Clear Out', 'Clearing Out'];
const RemoteOpenStatus = ['Ready for Collection', 'Require Verification', 'Clear Out'];

export default function IndividualOrder() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order>();
  const [logs, setLogs] = useState<OrderLog[]>([]);
  const [status, setStatus] = useState<Status>();
  const [expandedItems, setExpandedItems] = useState<boolean>(false);
  const logsRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { logo } = useAccounts();

  const handleDropdown = (ref: RefObject<HTMLDivElement | null>) => {
    if (ref.current) {
      ref.current.clientHeight
        ? (ref.current.style.height = '0')
        : (ref.current.style.height = `${ref.current?.scrollHeight}px`);
    }
  };

  const initData = useCallback(async () => {
    let response = await get(APIS.admin.order(id as string));
    if (response.ok) {
      setOrder(response.data);
    }

    response = await get(APIS.admin.orderLogs(id as string));
    if (response.ok) {
      setLogs(response.data);
    }

    const newResponse = await get(APIS.admin.statuses(id as string));
    if (newResponse.ok) {
      setStatus(newResponse.data);
    }
  }, [id]);

  const handleCancel = async () => {
    setLoading(true);
    const response = await patch(APIS.admin.cancelOrder(id as string));
    setLoading(false);

    if (response.ok) {
      let message = 'Order has been cancelled';

      if (response.data.status.toLowerCase().replace(' ', '_').includes('clear_out')) {
        message = 'Order is moved to clear out status';
      } else if (response.data.status.toLowerCase().includes('cancelled')) {
        message = 'Order has been cancelled';
      }
      showSuccessMessage(message);
      navigate(ROUTES.admin.viewOrders);
    }
  };

  const handleAdminOpenLocker = () => {
    if (order?.status == 'Ready for Collection') {
      navigate(ROUTES.admin.openLockerAdmin, {
        state: {
          id: order?.id,
          orderId: order?.order_id,
          customerName: order?.customer_name,
          lockerNum: order?.locker?.locker_no,
          previousRoute: 'individualOrder'
        }
      });
    } else if (order?.status == 'Require Verification') {
      navigate(ROUTES.admin.ageVerificationAdmin, {
        state: {
          id: order?.id,
          orderId: order?.id
        }
      });
    } else if (order?.status == 'Clear Out') {
      navigate(ROUTES.admin.clearOutOrder, {
        state: { id: id, orderId: order?.order_id, customerName: order?.customer_name }
      });
    }
  };

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <Layout
      backIcon={true}
      navigateToHome={true}
      logoIcon={logo}
      onBackClick={() => navigate(ROUTES.admin.viewOrders)}>
      <div className="individual-order">
        <div className="top-heading">Audit Trails/Logs</div>

        <div className="header">
          <div className="space-between-2">
            <span className="title">Order Status</span>
          </div>
          <div className="order-status">
            {order && (
              <div className="heading">
                Order is{' '}
                {order?.status == 'Clear Out' ? `Awaiting ${order?.status}` : order?.status}
              </div>
            )}
            <span className="order-date">Last updated {order?.order_date}</span>
          </div>
        </div>

        <div className="statuses">
          <div className="outter-block">
            <div className="circle-block">
              <div className="circle-blue" />
              <div className="dotted-line-blue" />
            </div>
            <div className="inner-block">
              <div className="status-name">Created</div>
              <span className="date-name">{status?.created_at}</span>
              <br />
              <span className="date-name">{status?.created_by}</span>
            </div>
          </div>
          <div className="outter-block">
            <div className="circle-block">
              <div
                className={`${
                  order?.status && LoadedStatus.includes(order?.status)
                    ? 'circle-blue'
                    : 'circle-gray'
                }`}
              />
              {order?.status && LoadedStatus.includes(order?.status) ? (
                <div className="dotted-line-blue" />
              ) : (
                <div className="dotted-line-gray" />
              )}
            </div>
            <div className="inner-block">
              <div className="status-name">Loaded</div>
              {order?.status && LoadedStatus.includes(order?.status) && (
                <>
                  <span className="date-name">{status?.loaded_at}</span>
                  <br />
                  <span className="date-name">{status?.loaded_by}</span>
                </>
              )}
            </div>
          </div>
          <div className="outter-block">
            <div className="circle-block">
              <div
                className={`${
                  order?.status && order?.status === 'Collected' ? 'circle-blue' : 'circle-gray'
                }`}
              />
            </div>
            <div className="inner-block">
              <div className="status-name">Completed</div>
              {order?.status && order?.status === 'Collected' && (
                <>
                  <span className="date-name">{status?.completed_at}</span>
                  <br />
                  <span className="date-name">{status?.completed_by}</span>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="updates">
          <div className="heading"> See All Updates </div>
          <div className="chevron-icon-cont">
            <img
              src={VectorIcon}
              alt="expand items"
              className={expandedItems ? `chevron-up-icon` : `chevron-down-icon`}
              onClick={() => {
                setExpandedItems(!expandedItems);
                handleDropdown(logsRef);
              }}
            />
          </div>
        </div>

        <div ref={logsRef} className="expand-container">
          {logs?.map(({ name, user, time, date }, index) => (
            <div key={index} className="updates-block">
              <div className="text">{truncateText(name, 80)}</div>
              <div className="details">
                <span className="date">
                  {date}, {time}
                </span>
                <span className="name">{user}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="buttons">
        {/* {order?.status && RemoteOpenStatus.includes(order?.status) && (
          <PrimaryButton
            className={`open-locker ${ExcludedCancelStatus.includes(order?.status) && 'w-55'}`}
            loading={loading}
            onClick={handleAdminOpenLocker}>
            Open Locker
          </PrimaryButton>
        )} */}
        {order?.status && !ExcludedCancelStatus.includes(order?.status) && (
          <PrimaryButton className={`cancel-order`} loading={loading} onClick={handleCancel}>
            Cancel Order
          </PrimaryButton>
        )}
      </div>
    </Layout>
  );
}
