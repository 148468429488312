import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/admin/ageVerificationPrompt.scss';

import PrimaryButton from 'components/shared/primaryButton.tsx';
import GrayButton from 'components/shared/grayButton.tsx';
import useBlurHandler from 'components/shared/hooks/useBlurHandler';

import CloseIcon from 'assets/images/close-icon-no-background.svg';
import VerifyAgeIcon from 'assets/images/verify-age-icon.svg';

import { APIS, ROUTES } from 'constants/index';
import { patch } from 'utils/networks';

interface Props {
  show: boolean;
  onClose: () => void;
  orderId: string;
}

export default function AgeVerificationPrompt({ show, onClose, orderId }: Props) {
  const { ref } = useBlurHandler(show, onClose);
  const [verifying, setVerifying] = useState<boolean>(false);
  const navigate = useNavigate();

  const verifyOrder = async () => {
    setVerifying(true);
    const response = await patch(APIS.admin.verifyOrder(orderId));
    setVerifying(false);

    if (response.ok) {
      navigate(ROUTES.admin.openLockerAdmin, {
        state: {
          id: response.data.id,
          orderId: response.data.order_id,
          customerName: response.data.customer_name,
          lockerNum: response.data.locker?.locker_no,
          previousRoute: 'ageVerification'
        }
      });
      return;
    }
    onClose();
  };

  return (
    <div className="age-verification-prompt">
      <div ref={ref} className="prompt">
        <img src={CloseIcon} alt="close" className="close-icon" onClick={onClose} />
        <img src={VerifyAgeIcon} alt="" className="verify-age-icon" />
        <div className="heading"> Verify Age </div>
        <div className="content">
          Challenge 25 does the customer look at least 25? if not ask them to prove they are least
          18 and their data of birth is on or before 23/8/2004.
        </div>
        <PrimaryButton
          loading={verifying}
          className=" blue-button fs-bold-light"
          onClick={verifyOrder}>
          Customer visibly 25 or Over
        </PrimaryButton>
        <GrayButton
          loading={verifying}
          className=" green-button fs-bold-light"
          onClick={verifyOrder}>
          Customer verified over 18
        </GrayButton>
        <GrayButton
          loading={verifying}
          className=" dark-button fs-bold-light"
          onClick={() =>
            navigate(`${ROUTES.admin.cancelVerifiedOrder}/${orderId}`, {
              state: { prevRoute: ROUTES.admin.ageVerificationAdmin }
            })
          }>
          Customer unable to prove age
        </GrayButton>
      </div>
    </div>
  );
}
