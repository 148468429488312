import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/admin/switchAccount.scss';

import Layout from 'components/shared/layout';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import { get, patch } from 'utils/networks';
import { showSuccessMessage } from 'utils';
import { APIS, ROUTES } from 'constants/index';

export default function SwitchAccount() {
  const navigate = useNavigate();
  const { accounts, dispatch, logo } = useAccounts();
  const [selected, setSelected] = useState<number>();
  const [loading, setLoading] = useState<number | null>(null);

  const handleAccountClick = async (id: number) => {
    if (loading !== null) return;
    setLoading(id);
    setSelected(id);

    const response = await patch(`${APIS.admin.accounts}?account_id=${id}`);

    if (response.ok) {
      dispatch({ type: 'setActive', id });
      setLoading(null);
      showSuccessMessage(response.data.result);
      navigate(ROUTES.admin.dashboard);
    }
  };

  const getAccounts = useCallback(async () => {
    const response = await get(APIS.admin.accounts);

    if (response.ok) {
      dispatch({ type: 'setData', data: response.data.accounts });
      setSelected(
        response.data.accounts.filter((account: { active: any }) => account.active)[0].id
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (accounts.length == 0) {
      getAccounts();
    } else {
      setSelected(accounts.filter((account: { active: any }) => account.active)[0].id);
    }
  }, [accounts, getAccounts]);

  return (
    <Layout backIcon={true} logoIcon={logo}>
      <div className="switch-account">
        <div className="heading"> Switch account </div>

        {accounts.map(({ id, name }) => (
          <div
            key={id}
            className={[
              'account-detail',
              id == selected ? 'selected-account' : '',
              loading == id ? 'disabled' : ''
            ].join(' ')}
            onClick={() => handleAccountClick(id)}>
            <span className="name"> {name} </span>
            <input type="radio" className="radio-button" readOnly checked={id == selected} />
          </div>
        ))}
      </div>
    </Layout>
  );
}
