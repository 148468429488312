import React from 'react';
import 'styles/errorPage.scss';

import { NetworkIssueIcon } from 'assets/images/network-issue-icon.js';

interface Props {
  heading: string;
  img?: string;
  error?: string;
}

export default function ErrorComponent({ heading, error, img }: Props) {
  return (
    <div className="error-page-cont">
      <img
        className="network-issue-icon"
        src={img ?? NetworkIssueIcon}
        alt="camera permission error"
      />
      <p className="heading"> {heading} </p>
      {error && <p className="error mt-6">{error}</p>}
    </div>
  );
}
