import React from 'react';
import 'styles/grayButton.scss';

interface Props {
  children: JSX.Element[] | JSX.Element | string;
  className?: string;
  onClick?: () => void;
  loading?: boolean;
}

export default function GrayButton({ children, className, loading, onClick }: Props) {
  return (
    <button
      className={['gray-button-cont', className ?? '', loading ? 'opacity-5' : ''].join(' ')}
      disabled={loading}
      onClick={onClick}
      type="button">
      {children}
    </button>
  );
}
