import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isObject } from 'lodash';
import 'styles/admin/dashboard.scss';

import Layout from 'components/shared/layout';
import Sidemenu from 'components/admin/sidemenu';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import AgeVerificationIcon from 'assets/images/age-verification-icon.svg';
import ChecklistIcon from 'assets/images/checklist-icon.svg';
import { ReactComponent as ClearOutIcon } from 'assets/images/open-locker-icon.svg';
import BoxIcon from 'assets/images/box-icon.svg';
import AddFileIcon from 'assets/images/add-file-icon.svg';
import RemoteOpenIcon from 'assets/images/lock-unlock-line.svg';

import { APIS, ORDER_STATUSES, ROUTES } from 'constants/index';
import { get, patch } from 'utils/networks';
import { TestIds } from 'utils/testing/testIds';
import { getAdminRole, getCandCRemoteOpenEnabled } from 'utils';

const { DASHBOARD_SCREEN } = TestIds;

export default function AdminDashboard() {
  const [sideMenu, setOpenSideMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const { logo } = useAccounts();
  const screens = [
    {
      Icon: ChecklistIcon,
      name: 'View Orders',
      handleClick: () => navigate(ROUTES.admin.viewOrders)
    },
    {
      Icon: BoxIcon,
      name: 'Load Order',
      handleClick: () => navigate(ROUTES.admin.loadOrders)
    },
    {
      Icon: ClearOutIcon,
      name: 'Clear Out Order',
      handleClick: () => navigate(ROUTES.admin.clearOutOrderList),
      iconClass: 'clear-out-icon'
    },
    {
      Icon: AgeVerificationIcon,
      name: 'ID Verification',
      handleClick: () => navigate(ROUTES.admin.ageVerificationAdmin)
    }
  ];
  const adminRole = getAdminRole();
  const accessToCreateOrder = true;
  accessToCreateOrder &&
    screens.unshift({
      Icon: AddFileIcon,
      name: 'Create Order',
      handleClick: () => navigate(ROUTES.admin.createOrder)
    });

  const remoteOpenEnabled = getCandCRemoteOpenEnabled();
  if (adminRole === 'EndCustomerAdmin' && remoteOpenEnabled === 'true') {
    screens.push({
      Icon: RemoteOpenIcon,
      name: 'Admin Open',
      handleClick: () => navigate(ROUTES.admin.lockers)
    });
  }

  const revertOrderLocker = useCallback(async (id: any) => {
    const response = await get(APIS.admin.order(id as string));

    if (response.data.status.toLowerCase().replaceAll(' ', '_') == ORDER_STATUSES.new_pending) {
      await patch(APIS.admin.revertOrderLocker(id));
    }
    localStorage.removeItem('previousPath');
    localStorage.removeItem('orderID');
  }, []);

  useEffect(() => {
    const previousPath = localStorage.getItem('previousPath');
    const orderIdToRevert = localStorage.getItem('orderID');
    if (orderIdToRevert && previousPath && previousPath.includes('/admin/locker/open')) {
      revertOrderLocker(orderIdToRevert);
    }
    return () => {
      if (localStorage.previousPath || localStorage.orderID) {
        localStorage.removeItem('previousPath');
        localStorage.removeItem('orderID');
      }
    };
  }, [revertOrderLocker]);

  return (
    <Layout menu={true} menuOnClick={() => setOpenSideMenu(!sideMenu)} logoIcon={logo}>
      <div className="dashboard-cont">
        {screens.map(({ Icon, name, handleClick, iconClass }, index) => (
          <div
            data-testid={`${DASHBOARD_SCREEN}_${index}`}
            key={index}
            className="card"
            onClick={handleClick}>
            <div className="icon">
              {isObject(Icon) ? (
                <Icon className={iconClass} />
              ) : (
                <img src={Icon} alt="" className={iconClass} />
              )}
            </div>
            <p className="screen-name"> {name} </p>
          </div>
        ))}
        {sideMenu && <Sidemenu showMenu={sideMenu} onClose={() => setOpenSideMenu(!sideMenu)} />}
      </div>
    </Layout>
  );
}
