import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import 'styles/customer/orderFeedback.scss';

import { getStoredItem, getCustomerToken } from 'utils';
import { APIS, ROUTES, ORDER_STATUSES } from 'constants/index';
import { Order } from 'types/order';
import { get } from 'utils/networks';

import PrimaryButton from 'components/shared/primaryButton.tsx';
import goodExperience from 'assets/images/good-experience.svg';
import excellentExperience from 'assets/images/excellent-experience.svg';
import badExperience from 'assets/images/bad-experience.svg';
import orderIcon from 'assets/images/order-icon.svg';
import NeedHelp from 'assets/images/need-help-cs-icon.svg';

import Layout from 'components/shared/layout';
import { LocationState } from 'types/locationState';
export default function OrderFeedback() {
  const location: LocationState = useLocation();
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index') || '';
  const experience = location.state?.experience || 'excellent';
  const brandingLogo = getStoredItem('customerBrandingLogo') || '';
  const token = getCustomerToken(index);
  const [order, setOrder] = useState<Order>();
  const navigate = useNavigate();
  const [canUnlockUntil, setCanUnlockUntil] = useState('');
  const [settingsEnabled, setSettingsEnabled] = useState<boolean>(false);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null); // Track interval

  const navigateUser = (path: string) => {
    if (!index) {
      console.error('Index is null or undefined');
      return;
    }

    navigate({
      pathname: path,
      search: `?${createSearchParams({
        index: index.toString()
      })}`
    });
  };

  const getOrder = useCallback(async () => {
    const response = await get(APIS.customer.order(token as string));
    if (response.ok) {
      if (response.data?.branding && response.data.branding.color) {
        document.documentElement.style.setProperty(
          '--branding-color',
          response.data.branding.color
        );
      }
      setOrder(response.data);
      if (response.data.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.active) {
        console.log('redirecting to time out page');
        navigateUser(ROUTES.customer.collectionWindowTimeOutPage);
      }
      if (response.data.can_unlock_until) {
        const date = new Date(response.data.can_unlock_until);
        const time = date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
        setCanUnlockUntil(time);
      }
    }
  }, [token]);

  const getContactUsInfo = useCallback(async () => {
    const response = await get(
      APIS.customer.contactUsSettings(token as string),
      undefined,
      undefined,
      undefined,
      true
    );

    if (response.ok) {
      setSettingsEnabled(true);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getOrder();
      getContactUsInfo();
    }
  }, [token, getOrder]);

  const handleReopen = () => {
    navigateUser(ROUTES.customer.openLockerCustomer);
  };

  useEffect(() => {
    if (
      order &&
      order.can_unlock_until &&
      order.status.toLowerCase() === ORDER_STATUSES.collecting
    ) {
      const canUnlockDate = new Date(order.can_unlock_until);
      const now = new Date();

      // if (canUnlockDate < now) {
      //   console.log('Unlock window has already expired. No need to set an interval.');
      //   return;
      // }

      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      const checkUnlockWindow = () => {
        const now = new Date();
        const timeDifference: any = canUnlockDate.getTime() - now.getTime();

        if (timeDifference > 0) {
          // Calculate remaining minutes and seconds
          const remainingMinutes = Math.floor(timeDifference / 60000); // 1 minute = 60000 ms
          const remainingSeconds = Math.floor((timeDifference % 60000) / 1000); // Remaining seconds

          // Log the remaining time
          console.log(
            `Time remaining: ${remainingMinutes} minutes and ${remainingSeconds} seconds`
          );
        }

        if (canUnlockDate < now) {
          getOrder(); // Call the API

          clearInterval(intervalRef.current!);
          if (order.status.toLowerCase().replaceAll(' ', '_') === ORDER_STATUSES.active) {
            console.log('redirecting to time out page');
            navigateUser(ROUTES.customer.collectionWindowTimeOutPage);
          }
        }
      };

      // Set up the interval to run every 3 seconds
      intervalRef.current = setInterval(checkUnlockWindow, 5000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [order]);

  return (
    <>
      <Layout logoIcon={brandingLogo}>
        <div className={['order-feedback-info', 'order-feedback-info_min-h-48'].join(' ')}>
          <div className="feedback">
            <div className="content-heading">Feedback Sent!</div>
            <div className="experience">
              <div className={['emoji', experience === 'bad' ? 'active' : ''].join(' ')}>
                <img src={badExperience} alt="bad" />
              </div>
              <div className={['emoji', experience === 'good' ? 'active' : ''].join(' ')}>
                <img src={goodExperience} alt="good" />
              </div>
              <div className={['emoji', experience === 'excellent' ? 'active' : ''].join(' ')}>
                <img src={excellentExperience} alt="excellent" />
              </div>
            </div>
            <div className="feedback-text mt-4 mb-4 gray6">
              We love hearing from you! Thank you for leaving feedback for us.
            </div>
          </div>
        </div>
        <>
          {order &&
          [ORDER_STATUSES.collecting].includes(order.status.toLowerCase()) &&
          order.enable_collection_window &&
          order.can_unlock_until &&
          new Date(order.can_unlock_until) > new Date() ? (
            <div className="order-info">
              <img src={orderIcon} />
              <PrimaryButton className="reopen-button" onClick={handleReopen}>
                Reopen
              </PrimaryButton>
              <div className="reopen-instructions mt-4 gray6">
                Your collection locker is available until {canUnlockUntil}.
              </div>
            </div>
          ) : (
            <>
              {order && order.status.toLowerCase() === ORDER_STATUSES.completed && (
                <div className="order-info">
                  <img src={orderIcon} />
                  <div className="order-heading">Your order is complete!</div>
                </div>
              )}
            </>
          )}
        </>
      </Layout>
      {settingsEnabled && (
        <PrimaryButton
          className="contact-us-button"
          onClick={() => navigateUser(ROUTES.customer.contactUs)}>
          {
            <>
              <img src={NeedHelp} alt="need-help-icon" />
              <span>
                Need help? <br /> Contact Us
              </span>
            </>
          }
        </PrimaryButton>
      )}
    </>
  );
}
