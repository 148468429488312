import React, { useState } from 'react';
// eslint-disable-next-line import/named
import { To, useLocation, useNavigate, useParams } from 'react-router-dom';
import 'styles/admin/cancelVerifiedOrder.scss';

import Layout from 'components/shared/layout';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import GrayButton from 'components/shared/grayButton.tsx';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import { APIS, ROUTES } from 'constants/index';
import { patch } from 'utils/networks';

export default function CancelOrder() {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const nextRoute =
    state?.prevRoute === ROUTES.admin.ageVerificationAdmin ? -1 : ROUTES.admin.ageVerificationAdmin;
  const { logo } = useAccounts();

  const onCancel = async () => {
    setLoading(true);
    const response = await patch(APIS.admin.cancelOrder(id as string));
    setLoading(false);

    if (response.ok) {
      navigate(nextRoute as To);
    }
  };

  const verifyOrder = async () => {
    setLoading(true);
    const response = await patch(APIS.admin.reactivateOrder(id as string));
    setLoading(false);

    if (response.ok) {
      navigate(nextRoute as To);
    }
  };

  return (
    <Layout logoIcon={logo}>
      <div className="cancel-verified-order">
        <h3 className="heading"> Do you want to cancel the order? </h3>
        <PrimaryButton loading={loading} onClick={onCancel}>
          Yes
        </PrimaryButton>
        <GrayButton loading={loading} onClick={verifyOrder}>
          No
        </GrayButton>
      </div>
    </Layout>
  );
}
