import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/admin/changePassword.scss';

import Layout from 'components/shared/layout';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import PasswordField from 'components/shared/form/passwordField';

import { APIS, ROUTES } from 'constants/index';
import { patch } from 'utils/networks';
import { showSuccessMessage } from 'utils';
import { TestIds } from 'utils/testing/testIds';

const { CHANGE_PASSWORD_MISMATCH_ERROR } = TestIds;

export default function ChangePassword() {
  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { logo } = useAccounts();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newPasswordRef.current?.value !== confirmPasswordRef.current?.value) {
      setError('<li>New and Confirm passwords should be same</li>');
      return;
    }

    setError('');
    const data = {
      current_password: currentPasswordRef.current?.value,
      new_password: newPasswordRef.current?.value,
      confirm_password: confirmPasswordRef.current?.value
    };
    setLoading(true);
    const response = await patch(APIS.admin.changePassword, data);
    setLoading(false);

    if (response.ok && response.data.result) {
      (event.target as HTMLFormElement).reset();
      showSuccessMessage(response.data.result);
      navigate(ROUTES.admin.dashboard);
    }
    if (response.data?.errors) {
      setError(`<li>${response.data.errors.join('</li><li>')}</li>`);
    }
  };

  return (
    <Layout backIcon={true} navigateToHome={true} logoIcon={logo}>
      <form className="change-password-form" onSubmit={handleSubmit}>
        <div className="heading"> Change Password </div>
        <label htmlFor="currentPassword"> Current Password *</label>
        <PasswordField
          id="currentPassword"
          name="currentPassword"
          placeholder="Enter Current Password"
          ref={currentPasswordRef}
          required
        />
        <label htmlFor="newPassword"> New Password *</label>
        <PasswordField
          id="newPassword"
          name="newPassword"
          placeholder="Enter New Password"
          ref={newPasswordRef}
          required
        />
        <label htmlFor="confirmPassword"> Confirm Password *</label>
        <PasswordField
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Enter Confirm Password"
          ref={confirmPasswordRef}
          required
        />
        {error && (
          <div
            data-testid={CHANGE_PASSWORD_MISMATCH_ERROR}
            className="password-error"
            dangerouslySetInnerHTML={{ __html: `<ul>${error}</ul>` }}
          />
        )}
        <PrimaryButton loading={loading} buttonType="submit">
          Change Password
        </PrimaryButton>
      </form>
    </Layout>
  );
}
