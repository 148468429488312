import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import 'styles/admin/ageVerification.scss';

import { LocationState } from 'types/locationState';
import Layout from 'components/shared/layout';
import SearchInput from 'components/shared/searchInput';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import ErrorComponent from 'components/shared/error';
import AgeVerificationPrompt from 'components/admin/ageVerification/prompt';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import ChevronRight from 'assets/images/chevron-right-white-icon.svg';
import TumbleweedIcon from 'assets/images/tumbleweed.gif';

import { Order } from 'types/order';
import { get, ResponseType, ErrorType } from 'utils/networks';
import { APIS, ORDER_STATUSES, ROUTES } from 'constants/index';
import { TestIds } from 'utils/testing/testIds';
import { truncateText, isResponseType } from 'utils';

const {
  AGE_VERIFICATION_ORDER_ID,
  AGE_VERIFICATION_ORDER_CUSTOMER_NAME,
  AGE_VERIFICATION_VERIFY_NOW_BUTTON
} = TestIds;

export default function AgeVerificationAdmin() {
  const location: LocationState = useLocation();
  const navigate = useNavigate();
  const [showPrompt, setShowPrompt] = useState<string | undefined>();
  const [search, setSearch] = useState<string>('');
  const [orders, setOrders] = useState<Order[]>([]);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const { logo } = useAccounts();
  const [totalPagesCount, setTotalPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nextPage, setNextPage] = useState<number>(0);
  const [ordersPerPage, setOrdersPerPage] = useState<number>(60);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const orderId = location.state?.orderId;
    if (orderId) {
      setShowPrompt(orderId);
    }
  }, [location]);

  const getOrders = async (query: string, load_more = false) => {
    setLoading(true);
    const params = load_more
      ? {
          query: query,
          status: ORDER_STATUSES.require_verification,
          page: nextPage,
          per_page: ordersPerPage
        }
      : {
          query: query,
          status: ORDER_STATUSES.require_verification
        };
    const newParams = queryString.stringify(params);

    const response: ResponseType | ErrorType = await get(`${APIS.admin.orders}?${newParams}`);

    if (isResponseType(response) && response.ok) {
      setOrders((prevOrders) => [...prevOrders, ...response.data]);
      setTotalPageCount(parseInt(response.headers['x-total-pages'] || '0', 10));
      setOrdersPerPage(parseInt(response.headers['x-per-page'] || '0', 10));
      setNextPage(parseInt(response.headers['x-next-page'] || '0', 10));
      setCurrentPage(parseInt(response.headers['x-page'] || '0', 10));

      if (!response.data?.length) {
        setIsListEmpty(true);
        return;
      }
      setIsListEmpty(false);
      setLoading(false);
    }
  };

  const handlePromptOpen = (orderId: string) => {
    setShowPrompt(orderId);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounce((q: string) => getOrders(q), 500),
    []
  );

  useEffect(() => {
    setOrders([]);
    delayedSearch(search);
  }, [search, delayedSearch]);

  return (
    <Layout
      backIcon={true}
      navigateToHome={true}
      logoIcon={logo}
      onBackClick={() => navigate(ROUTES.admin.dashboard)}>
      <div className="age-verification">
        <SearchInput
          value={search}
          placeholder="Search Verification by name, id..."
          handleChange={(e: string) => {
            setSearch(e);
          }}
        />

        {orders.map((order) => {
          const { id, order_id, customer_name } = order;
          return (
            <div className="card" key={id}>
              <div data-testid={`${AGE_VERIFICATION_ORDER_ID}_${id}`} className="order-no">
                {truncateText(order_id, 15)}
              </div>
              <div data-testid={`${AGE_VERIFICATION_ORDER_CUSTOMER_NAME}_${id}`} className="name">
                {customer_name}
              </div>
              <PrimaryButton className="button" onClick={() => handlePromptOpen(order.id)}>
                <span data-testid={`${AGE_VERIFICATION_VERIFY_NOW_BUTTON}_${id}`} className="child">
                  <span> Verify Now </span>
                  <img src={ChevronRight} alt="" />
                </span>
              </PrimaryButton>
            </div>
          );
        })}

        {isListEmpty && (
          <ErrorComponent
            img={TumbleweedIcon}
            heading="ID Verification List Empty"
            error="You’re all good. There is nothing to verify."
          />
        )}

        {showPrompt && (
          <AgeVerificationPrompt
            show={!!showPrompt}
            onClose={() => setShowPrompt(undefined)}
            orderId={showPrompt}
          />
        )}

        {totalPagesCount > 1 && currentPage < totalPagesCount && orders.length > 1 && (
          <PrimaryButton
            className={`load-more`}
            loading={loading}
            onClick={() => getOrders(search, true)}>
            Load More
          </PrimaryButton>
        )}
      </div>
    </Layout>
  );
}
