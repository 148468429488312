import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import Select from 'react-select';
import 'styles/admin/lockers.scss';
import ErrorComponent from 'components/shared/error';

import Layout from 'components/shared/layout';
import SearchInput from 'components/shared/searchInput';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import RightArrow from 'assets/images/right-arrow-white-icon.svg';
import Lock from 'assets/images/lock-line.svg';
import orderDetail from 'assets/images/order-detail.svg';
import TumbleweedIcon from 'assets/images/tumbleweed.gif';

import { APIS, ROUTES } from 'constants/index';
import { get, ResponseType, ErrorType } from 'utils/networks';
import { truncateText, isResponseType } from 'utils';
import { LockerTag, OrderLocker, Order, Location } from 'types/order';
import { CustomSingleValue, CustomOption } from 'components/shared/LockerLocationSelect';

interface OptionType {
  value: string;
  label: string;
}

interface Lockers extends OrderLocker {
  tag: string;
  order?: Order;
}

export default function Lockers() {
  const [search, setSearch] = useState<string>('');
  const [lockers, setLockers] = useState<Lockers[]>([]);
  const [lockerTags, setLockerTags] = useState<OptionType[]>([]);
  const navigate = useNavigate();
  const { logo } = useAccounts();

  const tag: OptionType = {
    value: 'all lockers',
    label: 'All lockers'
  };
  const initialLocation: OptionType = {
    value: '',
    label: 'All locations'
  };
  const [selectedTag, setSelectedTag] = useState<OptionType>(tag);
  const [selectedLocation, setSelectedLocation] = useState<OptionType>(initialLocation);
  const [locations, setLocations] = useState<OptionType[]>([]);
  const [showLocationSelect, setShowLocationSelect] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [totalPagesCount, setTotalPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nextPage, setNextPage] = useState<number>(0);
  const [lockersPerPage, setLockersPerPage] = useState<number>(60);
  const [loading, setLoading] = useState<boolean>(false);

  const getLockers = useCallback(
    async (q: string, tag: string, location_uids: string) => {
      let queryStringValue = {};
      if (location_uids !== '') {
        const ids = [location_uids];
        queryStringValue = {
          q: q,
          tag: tag,
          location_uids: ids
        };
      } else {
        queryStringValue = {
          q,
          tag
        };
      }
      const newParams = queryString.stringify(queryStringValue, {
        arrayFormat: 'bracket'
      });
      const response: ResponseType | ErrorType = await get(`${APIS.admin.lockers}?${newParams}`);
      if (isResponseType(response) && response.ok) {
        setLockers(
          response.data
            .sort((a: OrderLocker, b: OrderLocker) =>
              Number(a.locker_no) < Number(b.locker_no) ? -1 : 1
            )
            .map((locker: OrderLocker) => ({
              ...locker,
              tag: selectedTag.label
            }))
        );
        setTotalPageCount(parseInt(response.headers['x-total-pages'] || '0', 10));
        setLockersPerPage(parseInt(response.headers['x-per-page'] || '0', 10));
        setNextPage(parseInt(response.headers['x-next-page'] || '0', 10));
        setCurrentPage(parseInt(response.headers['x-page'] || '0', 10));

        setLoaded(true);
      }
    },
    [selectedTag.label]
  );

  const loadMore = async () => {
    setLoading(true);
    let queryStringValue = {};
    if (selectedLocation.value == '') {
      queryStringValue = {
        q: search,
        tag: selectedTag.value === 'all lockers' ? '' : selectedTag.value,
        page: nextPage,
        per_page: lockersPerPage
      };
    } else {
      const ids = [selectedLocation.value];
      queryStringValue = {
        q: search,
        tag: selectedTag.value === 'all lockers' ? '' : selectedTag.value,
        location_uids: ids,
        page: nextPage,
        per_page: lockersPerPage
      };
    }

    const newParams = queryString.stringify(queryStringValue, {
      arrayFormat: 'bracket'
    });

    const response: ResponseType | ErrorType = await get(`${APIS.admin.lockers}?${newParams}`);

    if (isResponseType(response) && response.ok) {
      setLockers((prevLockers) => [
        ...prevLockers,
        ...response.data
          .sort((a: OrderLocker, b: OrderLocker) =>
            Number(a.locker_no) < Number(b.locker_no) ? -1 : 1
          )
          .map((locker: OrderLocker) => ({
            ...locker,
            tag: selectedTag.label
          }))
      ]);
      setTotalPageCount(parseInt(response.headers['x-total-pages'] || '0', 10));
      setLockersPerPage(parseInt(response.headers['x-per-page'] || '0', 10));
      setNextPage(parseInt(response.headers['x-next-page'] || '0', 10));
      setCurrentPage(parseInt(response.headers['x-page'] || '0', 10));

      setLoaded(true);
      setLoading(false);
    }
  };

  const getLockerTags = useCallback(async () => {
    const response = await get(APIS.admin.lockerTagsWithoutOrderId);

    if (response.ok) {
      setLockerTags([
        { value: 'all lockers', label: 'All Lockers' },
        ...response.data.tags.map(({ name }: LockerTag) => ({
          value: name.toLowerCase(),
          label: name
        }))
      ]);
    }
  }, []);

  const getLocations = useCallback(async () => {
    const response = await get(APIS.admin.locations);

    if (response.ok) {
      setShowLocationSelect(response.data.length > 1);
      if (response.data.length === 1) {
        setLocations([
          ...response.data.map(({ uid, name }: Location) => ({
            value: uid,
            label: name
          }))
        ]);
        setSelectedLocation({
          value: response.data[0]['uid'],
          label: response.data[0]['name']
        });
      } else {
        setLocations([
          { value: '', label: 'All locations' },
          ...response.data.map(({ uid, name }: Location) => ({
            value: uid,
            label: name
          }))
        ]);
      }
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounce(
      (q: string, tag: string, location_uids?: string) => getLockers(q, tag, location_uids || ''),
      500
    ),
    [getLockers]
  );

  useEffect(() => {
    delayedSearch(
      search,
      selectedTag.value === 'all lockers' ? '' : selectedTag.value,
      selectedLocation?.value || ''
    );
  }, [delayedSearch, getLockerTags, selectedTag.value, search, selectedLocation?.value]);

  useEffect(() => {
    getLockerTags();
    getLocations();
  }, [getLockerTags, getLocations]);

  return (
    <Layout backIcon={true} customerName={''} navigateToHome={true} logoIcon={logo}>
      <div className="lockers">
        <SearchInput
          value={search}
          placeholder="Search Lockers by Locker Number"
          handleChange={(e: string) => setSearch(e)}
        />
        <div className="select-container">
          <div className={`location`}>
            <Select
              options={locations}
              placeholder="Choose location"
              className="sizes-dropdown"
              value={selectedLocation}
              onChange={(e) => e && setSelectedLocation(e)}
              isSearchable={false}
              isDisabled={!showLocationSelect}
              components={
                showLocationSelect
                  ? { SingleValue: CustomSingleValue, Option: CustomOption }
                  : {
                      SingleValue: CustomSingleValue,
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null
                    }
              }
            />
          </div>
          <div className={`locker-tags`}>
            <Select
              options={lockerTags}
              placeholder="Choose Size"
              className="sizes-dropdown"
              value={selectedTag}
              onChange={(e) => e && setSelectedTag(e)}
              isSearchable={false}
            />
          </div>
        </div>

        {lockers.map(({ id, locker_no, order }) => (
          <div key={id} className="locker">
            <span className="left-container">
              <span>
                <img src={Lock} alt="" className="right-arrow" />
              </span>
              <span className="locker-id">{locker_no}</span>
            </span>
            {order && (
              <div className="order_details">
                <span
                  className={['status', order.status.toLowerCase().replaceAll(' ', '-')].join(' ')}>
                  <span className="dot" /> {order.status}
                </span>
                <span className="bottom-container">
                  <span>
                    <img src={orderDetail} alt="" className="right-arrow" />
                  </span>
                  <span className="order_no">{truncateText(order.order_id, 10)}</span>
                </span>
              </div>
            )}
            <PrimaryButton
              className="load-button"
              onClick={() =>
                navigate(ROUTES.admin.forceOpenLocker, {
                  state: {
                    id: id,
                    lockerId: locker_no,
                    customerName: order ? order.customer_name : null,
                    order: order ? true : false,
                    orderId: order ? order.order_id : null
                  }
                })
              }>
              <span className="align-center">
                <span>Open</span> <img src={RightArrow} alt="" className="right-arrow" />
              </span>
            </PrimaryButton>
          </div>
        ))}

        {loaded && lockers.length === 0 && (
          <ErrorComponent img={TumbleweedIcon} heading="No Lockers Available" />
        )}

        {totalPagesCount > 1 && currentPage < totalPagesCount && (
          <PrimaryButton className={`load-more`} loading={loading} onClick={() => loadMore()}>
            Load More
          </PrimaryButton>
        )}
      </div>
    </Layout>
  );
}
